import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppSettings } from './services/constants/constants';

//Additional 
//Model
import { NgbModule, NgbDate, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//progress bar
import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
//Toast Service
import { ToastrModule } from 'ngx-toastr';
//Select drop down
import { NgSelectModule } from '@ng-select/ng-select';
//Map
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
//EDITOR
import { CKEditorModule } from 'ngx-ckeditor';
//Table
import { DataTablesModule } from 'angular-datatables';
//Date Pikker
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
//Image Compress
import { NgxImageCompressService } from 'ngx-image-compress';
import { Ng2ImgMaxModule } from 'ng2-img-max';
//Decimal
import { NgxCurrencyModule } from "ngx-currency";
//Barcode
import { NgxBarcodeModule } from 'ngx-barcode';
import { QRCodeModule } from 'angularx-qrcode';
//Sidebar
import { SidebarModule } from 'ng-sidebar';
//Device Info
import { DeviceDetectorModule } from 'ngx-device-detector';
//Session
import { BnNgIdleService } from 'bn-ng-idle';
//Firebase
// import {AngularFireModule} from 'angularfire2'
// import {AngularFireDatabaseModule} from 'angularfire2/database'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

//Chart
import { NgxChartsModule } from '@swimlane/ngx-charts';

//Drag
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ag Grid
import { AgGridModule } from 'ag-grid-angular';

import { NgxEmojiPickerModule } from 'ngx-emoji-picker';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AngularMaterialModule } from './angular-material.module';

import { IConfig, NgxMaskModule } from 'ngx-mask'

import { NgxPaginationModule } from 'ngx-pagination';

import { Ng2SearchPipeModule } from 'ng2-search-filter';

//Component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';

// service
import { DhukanDataService } from './services/dhukan/dhukan-data.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';


import { TwoDigitDecimaNumberDirectiveDirective } from './two-digit-decima-number-directive.directive';
import { LoginComponent } from './components/login/login.component';
import { DateFormatPipe } from './date-format.pipe';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { DisablecontrolDirective } from './disablecontrol.directive';
import { HttpcancelService } from './services/dhukan/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageHttpInterceptor } from './interceptor/managehttp.interceptor';
import { ProcessComponent } from './components/process/process.component';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore/angular-fire-firestore';
import { ImageformatterComponent } from './components/grid/imageformatter/imageformatter.component';
import { ButtonformatterComponent } from './components/grid/buttonformatter/buttonformatter.component';
import { ActionformatterComponent } from './components/grid/actionformatter/actionformatter.component';
import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';
import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';

import { PurchaseComponent } from './componentstrnsaction/purchase/purchase.component';
import { AddpurchaseComponent } from './componentstrnsaction/purchase/addpurchase/addpurchase.component';

import { AdduompopComponent } from './components/generalpop/adduompop/adduompop.component';

import { PendingChangesGuard } from './components/PendingChangesGuard';
import { NgMaskCusDirectiveDirective } from './ng_mask_cus-directive.directive';



import { RenogridComponent } from './components/grid/renogrid/renogrid.component';


import { SortDirective } from './sort.directive';
import { InstituteComponent } from './components/institute/institute.component';
import { AddinstituteComponent } from './components/institute/addinstitute/addinstitute.component';
import { MohallaComponent } from './components/mohalla/mohalla.component';
import { AddmohallaComponent } from './components/mohalla/addmohalla/addmohalla.component';
import { ProfessionComponent } from './components/profession/profession.component';
import { AddprofessionComponent } from './components/profession/addprofession/addprofession.component';
import { QualificationComponent } from './components/qualification/qualification.component';
import { AddqualificationComponent } from './components/qualification/addqualification/addqualification.component';
import { MemberComponent } from './components/member/member.component';
import { AddmemberComponent } from './components/member/addmember/addmember.component';
import { FamilyComponent } from './components/family/family.component';
import { AddfamilyComponent } from './components/family/addfamily/addfamily.component';
import { EditfamilyComponent } from './components/family/editfamily/editfamily.component';
import { RelationshipComponent } from './components/relationship/relationship.component';
import { AddrelationshipComponent } from './components/relationship/addrelationship/addrelationship.component';
import { EditfamilypopComponent } from './components/family/editfamilypop/editfamilypop.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { StreetComponent } from './components/street/street.component';
import { AddstreetComponent } from './components/street/addstreet/addstreet.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { CompanyComponent } from './components/company/company.component';
import { TempfamilyComponent } from './components/family/tempfamily/tempfamily.component';
import { AddmembershortComponent } from './components/member/addmembershort/addmembershort.component';
import { PrintfamilyComponent } from './components/family/printfamily/printfamily.component';
import { EditaddressComponent } from './components/member/editaddress/editaddress.component';
import { TaxComponent } from './components/tax/tax.component';
import { ViewtaxComponent } from './components/tax/viewtax/viewtax.component';
import { AddtaxComponent } from './components/tax/addtax/addtax.component';
import { MemberreportComponent } from './components/report/memberreport/memberreport.component';
import { VmjprreportComponent } from './components/report/vmjprreport/vmjprreport.component';
import { TaxpayerreportComponent } from './components/report/taxpayerreport/taxpayerreport.component';
import { QualificationreportComponent } from './components/report/qualificationreport/qualificationreport.component';
import { BloodgroupreportComponent } from './components/report/bloodgroupreport/bloodgroupreport.component';
import { ExportgridComponent } from './components/grid/exportgrid/exportgrid.component';
import { ReportgridComponent } from './components/grid/reportgrid/reportgrid.component';
import { WorkingabroadreportComponent } from './components/report/workingabroadreport/workingabroadreport.component';
import { WorkingoutcityreportComponent } from './components/report/workingoutcityreport/workingoutcityreport.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};



const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: "",
  separatorLimit: "9999999999",
  allowNegativeNumbers: true,
  dropSpecialCharacters: false,
  patterns: {
    'V': { pattern: new RegExp('-?') },
    '0': { pattern: new RegExp('[0-9]') }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavigationComponent,
    HeaderComponent,
    ConfirmationDialogComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    NgMaskCusDirectiveDirective,
    LoginComponent,
    DateFormatPipe,
    DatetimeFormatPipe,
    LoadingComponent,
    DisablecontrolDirective,
    ProcessComponent,
    ImageformatterComponent,
    ButtonformatterComponent,
    ActionformatterComponent,
    UomComponent,
    AdduomComponent,
    UserComponent,
    AdduserComponent,
    UserroleComponent,
    AdduserroleComponent,
    PurchaseComponent,
    AddpurchaseComponent,
    AdduompopComponent,
    RenogridComponent,
    SortDirective,
    InstituteComponent,
    AddinstituteComponent,
    MohallaComponent,
    AddmohallaComponent,
    ProfessionComponent,
    AddprofessionComponent,
    QualificationComponent,
    AddqualificationComponent,
    MemberComponent,
    AddmemberComponent,
    RelationshipComponent,
    AddrelationshipComponent,
    FamilyComponent,
    EditfamilyComponent,
    AddfamilyComponent,
    EditfamilypopComponent,
    CityComponent,
    AddcityComponent,
    StreetComponent,
    AddstreetComponent,
    CompanyComponent,
    AddcompanyComponent,
    TempfamilyComponent,
    AddmembershortComponent,
    PrintfamilyComponent,
    EditaddressComponent,
    TaxComponent,
    ViewtaxComponent,
    AddtaxComponent,
    MemberreportComponent,
    VmjprreportComponent,
    TaxpayerreportComponent,
    QualificationreportComponent,
    BloodgroupreportComponent,
    ExportgridComponent,
    ReportgridComponent,
    WorkingabroadreportComponent,
    WorkingoutcityreportComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    NgSelectModule,
    DragDropModule,
    CKEditorModule,
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    QRCodeModule,
    SidebarModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxEmojiPickerModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-center',
      titleClass: 'titleClass'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxChartsModule,
    AngularMaterialModule,
    AutocompleteLibModule,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, NgxImageCompressService, DateFormatPipe, Location, PendingChangesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true },],
  bootstrap: [AppComponent],
  exports: [BrowserModule, TranslateModule, BsDatepickerModule],
  entryComponents: [ConfirmationDialogComponent, AdduompopComponent, AddinstituteComponent, AddmohallaComponent, AddprofessionComponent, AddqualificationComponent,
    AddpurchaseComponent, AddfamilyComponent,EditfamilypopComponent,AddcityComponent,AddstreetComponent,AddmembershortComponent,PrintfamilyComponent,EditaddressComponent,AddtaxComponent],
})
export class AppModule { }
