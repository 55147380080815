import { DatePipe } from '@angular/common';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ExportgridComponent } from '../../grid/exportgrid/exportgrid.component';

@Component({
  selector: 'app-memberreport',
  templateUrl: './memberreport.component.html',
  styleUrls: ['./memberreport.component.css']
})
export class MemberreportComponent implements OnInit {

  @ViewChild('filterbar', { static: false }) filterbar;

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;
  isfilter: boolean = false;


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;


  formname: string = "MemberReport";


  gridformtype: string = "";

  checkemp: string = "";
  companycheckColl: any = [];
  filterstatus: string = "ALL";
  salesreportColl: any = [];
  stationColl: any = [];
  groupColl: any = [];
  subgroupColl: any = [];
  productColl: any = [];
  reasonColl: any = [];


  fromdate: any = new Date();
  todate: any = new Date();
  filterdinetypeid: number = null;
  filtergroupid: number = null;
  filtersubgroupid: number = null;
  filterstationid: number = null;
  filterproductid: number = null;
  filterreasonid: number = null;
  memberreportColl: any = [];
  searchtxt: any = "";

  columnDefs = [
    { headerName: 'VMJPR No', field: 'familyno' },
    { headerName: 'Family Nick Name', field: 'familynickname' },
    { headerName: 'Member Name', field: 'membername' },
    { headerName: 'Father/Husband', field: 'fathername' },
    { headerName: 'Gender', field: 'gendername' },
    { headerName: 'MaritalStatus', field: 'maritalstatusname' },
    { headerName: 'Age', field: 'age' },
    { headerName: 'Adhaar No', field: 'adhaarnoen' },
    { headerName: 'Qualification', field: 'qualificationname' },
    { headerName: 'Door No', field: 'doorno' },
    { headerName: 'Street', field: 'streetname' },

  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };

  dateCustomClasses: DatepickerDateCustomClasses[];


  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal, private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) {
    this.fnServiceChanges();


  }

  //Company Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });

  }


  ngOnInit() {
    try {

      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnOnInitForm() {
    this.fngetMemberReport();

  }


  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');

    this.isview = true;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }






  //Get
  fngetMemberReport() {
    try {
      this.fnShowProgress()

      var data = {
        'formname': 'MEMBER',
      }

      this.appService.fnGetReportAll(data)
        .subscribe(
          (res) => {
            this.fnHideProgress()

            if (res.json().status === 200) {
              this.memberreportColl = res.json().result[0];

            } else if (res.json().status === 400) {
              this.toastr.error("Error", res.json().err_field)
            }
          },
          (err) => {
            this.fnHideProgress()
            this.toastr.error('Error', err);
          })
    } catch (ex) {
      this.fnHideProgress()
      this.fnShowErrorMsg(ex)
    }
  }



  fnSetTable() {

  }

  private fnFocusTableRow(index, status) {
    if (index > -1) {
      if (status === 'IN') {
        this.memberreportColl[index].hoverfocus = "IN"
      }
      else {
        this.memberreportColl[index].hoverfocus = ""
      }
    }
  }

  fnTabHeaderClick(type) {
    this.searchtxt = "";
    this.gridformtype = type;
  }

  fnCloseForm() {
    try {
      this.router.navigate(['/Report']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xxl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.memberreportColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });
  }


  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }

  }
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }
  // private fnExport() {

  //   const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'l', backdrop: 'static' });
  //   modalRef.componentInstance.doctype = this.formname;
  //   modalRef.componentInstance.columnDefs = this.columnDefs;
  //   modalRef.componentInstance.contentColl = this.activitylogColl;
  //   modalRef.componentInstance.fnSetTable();
  //   modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


  //   });

  // }
  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }









}
