import { EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { EditfamilypopComponent } from '../../family/editfamilypop/editfamilypop.component';
import { PrintfamilyComponent } from '../../family/printfamily/printfamily.component';
import { AddmemberComponent } from '../../member/addmember/addmember.component';
import { AddmembershortComponent } from '../../member/addmembershort/addmembershort.component';
import { EditaddressComponent } from '../../member/editaddress/editaddress.component';
import { AddtaxComponent } from '../addtax/addtax.component';

@Component({
  selector: 'app-viewtax',
  templateUrl: './viewtax.component.html',
  styleUrls: ['./viewtax.component.css']
})
export class ViewtaxComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  familyColl: any = [];
  familyBO: any = {};

  familymemberColl: any = [];

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Family";
  entrymode: String = "";
  rtype: String = "";

  @Input() familyid: number = 0;
  @Input() familyname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, public router: Router, private productFindService: NgbModal, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.familyid = params.id
      }
      if (params.entrymode !== undefined) {
        this.entrymode = params.entrymode
      }
      if (params.rtype !== undefined) {
        this.rtype = params.rtype
      }
      
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      this.isaddmode = true;
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {


      if (this.familyid > 0) {
        this.fngetTaxDetailsByFamilyId();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.familyid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }



  //Get 
  fngetTaxDetailsByFamilyId() {
    var data = {
      'familyid': this.familyid,
    }
    this.appService.getTaxDetailsByFamilyId(data)
      .subscribe(
        (res) => {
  
          var result = res.json().result;
        console.log(result)

          this.familyColl = result[0];
          if (this.familyColl.length > 0) {
            this.familyBO = this.familyColl[0];  
          }

          this.familymemberColl= result[1];
          

          var taxdetailsColl = result[2];
          
          for (let abo of this.familymemberColl) {
            var tempdetailsColl = []
            
            for (let pbo of taxdetailsColl) {
              if (abo.memberid === pbo.memberid) {
                tempdetailsColl.push(pbo)
              }
            }
            abo.taxdetailsColl = tempdetailsColl;
          }


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Close
  fnCloseFamily() {
    try {
      if (this.rtype === "FAMILY") {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': this.familyid,
          }
        }
  
        this.router.navigate(['/AddFamily'], navigationExtras);
      }
      else {
        this.router.navigate(['/Tax']);
      }


    
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnAddOpening(abo) {
  
    const modalRef = this.productFindService.open(AddtaxComponent, { size: <any>'m', backdrop: 'static', keyboard: false, });
    modalRef.componentInstance.taxid =0;
    modalRef.componentInstance.familyid = this.familyBO.familyid; 
    modalRef.componentInstance.memberid = abo.memberid;
    modalRef.componentInstance.isopening = true; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    this.fngetTaxDetailsByFamilyId()
    });
  }



  fnAddPayment(abo) {
  
    const modalRef = this.productFindService.open(AddtaxComponent, { size: <any>'m', backdrop: 'static', keyboard: false, });
    modalRef.componentInstance.taxid =0;
    modalRef.componentInstance.familyid = this.familyBO.familyid; 
    modalRef.componentInstance.memberid = abo.memberid;
    modalRef.componentInstance.isopening = false; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    this.fngetTaxDetailsByFamilyId()
    });
  }


  fnEditPayment(abo) {
  
    const modalRef = this.productFindService.open(AddtaxComponent, { size: <any>'m', backdrop: 'static', keyboard: false, });
    modalRef.componentInstance.taxid =abo.taxid;
    modalRef.componentInstance.familyid = this.familyBO.familyid; 
    modalRef.componentInstance.memberid = abo.memberid;
    modalRef.componentInstance.isopening = abo.isopening; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    this.fngetTaxDetailsByFamilyId()
    });
  }

  public fnDeleteConfirm(pBO) {
    try {

      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete payment?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteTax(pBO)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  
  private fnDeleteTax(pBO) {
    var taxid = pBO.taxid
    var data = {
      'taxid': taxid,
    }
    this.appService.fnDeleteTax(data)
      .subscribe(
        (res) => {
          console.log(res.json())
          if (res.json().status === 200) {
            //var result = res.json().result;
            this.toastr.success('', " Payment has been deleted successfully.");

            this.fngetTaxDetailsByFamilyId()
            
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


   
  fnViewFamilyDetails() {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.familyid,   
          'rtype':"TAX"      
        }
      }

      this.router.navigate(['/AddFamily'], navigationExtras)
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }




  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
