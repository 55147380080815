import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-adduompop',
  templateUrl: './adduompop.component.html',
  styleUrls: ['./adduompop.component.css']
})
export class AdduompopComponent implements OnInit  {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  uomColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "uom";

  uomform = new FormGroup({
    uomid: new FormControl(null),
    uomname: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });


  @Input() uomid: number = 0;
  @Input() uomname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.uomid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.uomform.reset();
      this.fnInitializeuomFormGroup();

      if (this.uomid > 0) {
        this.fngetuom();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.uomid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeuomFormGroup() {
    this.uomform.setValue({
      uomid: 0,
      uomname: '',
      inactive: false,
    })
  }





  //Get 
  fngetuom() {
    var data = {
      'uomid': this.uomid,
    }
    this.appService.getUom(data)
      .subscribe(
        (res) => {
          this.uomColl = res.json().result;
          var editdata = this.uomColl[0];
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.uomform.valid) {
        //Check
        var data = {
          'uomid': this.uomid,
          'uomname': this.uomform.get('uomname').value,
          'inactive': this.uomform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckUom(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.uomform.get('uomid').value > 0) {
                //Update Department
                this.fnSetUom(data);
              }
              else {
                //Insert Department
                this.fnInsertUom(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.uomform.controls['uomname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertUom(data) {

    this.appService.fnInsertUom(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var uomid = 0;
          if (result.length > 0) {
            uomid = result[0].uomid
          }

          this.fnInsertActivityLog('INSERT', 0, uomid);

          this.toastr.success('', "The new uom has been added successfully.Insert Successfully");
          this.emitData.emit(uomid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetUom(data) {

    this.appService.fnSetUom(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The uom has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.uomid, data.uomname);

          this.emitData.emit(this.uomid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.uomform.setValue({
      uomid: deptBO.uomid,
      uomname: deptBO.uomname,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
