import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { AddpurchaseComponent } from './addpurchase/addpurchase.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit, AfterViewInit, OnDestroy {


  purchaseColl: any = [];

  branchColl: any = [];


  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  istableloading: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Purchase";

  filterbranchid: number = 0;
  filterfromdate: any = new Date();
  filtertodate: any = new Date();
  filtersearchtxt: any = "";

  searchtxt: any = "";
  columnDefs = [
   

  
    { headerName: 'Purchase No', field: 'purchaseno' },
    { headerName: 'Date', field: 'purchasedate', type: 'date' },
    { headerName: 'Branch', field: 'branchname' },
    { headerName: 'Supplier', field: 'partyname' },
    { headerName: 'Grand Total', field: 'grandtotal',type: 'decimal' },
   
  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal, private route: ActivatedRoute, private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) {
    this.fnGetParameterVariable();
    this.fnServiceChanges();
  }

  fnGetParameterVariable() {
    this.route.queryParams.subscribe(params => {
      if (params.branchid !== undefined) {
        this.filterbranchid = params.branchid
      }
    })
  }
  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {

    

      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
          this.filterfromdate.setDate(this.filterfromdate.getDate() - 15);
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {

    this.fngetBranch();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
  
  }




  fngetBranch() {
    var data = {
      'branchid': 0,
    }
    // this.appService.getBranch(data)
    //   .subscribe(
    //     (res) => {
    //       if (res.json().status === 200) {
    //         this.branchColl = res.json().result;
    //         if (this.branchColl.length > 0) {
    //           this.filterbranchid = this.branchColl[0].branchid;
    //         }


    //         this.fngetPurchaseListview();
    //       }
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }
  fngetPurchaseListview() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'purchaseid': 0,
      'fromdate': this.fnFormatDatetime(this.filterfromdate),
      'todate': this.fnFormatDatetime(this.filtertodate),
      'branchid': this.filterbranchid,
    }
    // this.appService.getPurchaseByListview(data)
    //   .subscribe(
    //     (res) => {

    //       this.fnHideProgress();
    //       if (res.json().status == 200) {
    //         this.purchaseColl = res.json().result;

    //         this.fnSetDataTable();
    //       }
    //       else if (res.json().status == 400) {
    //         this.toastr.error('Error', res.json().err_field);
    //       }


    //     },
    //     (err) => {
    //       this.fnHideProgress();
    //       this.toastr.error('Error', err);
    //     })
  }


  fnAddPurchase() {
    try {
      const modalRef = this.productFindService.open(AddpurchaseComponent, { size: <any>'xl', backdrop: 'static' });

      modalRef.componentInstance.filterbranchid = this.filterbranchid;
      modalRef.componentInstance.filterfromdate = this.filterfromdate;
      modalRef.componentInstance.filtertodate = this.filtertodate;
      modalRef.componentInstance.ngOnInit();

      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        this.fngetPurchaseListview();

      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditPurchase(pBO) {
    try {
      var deptid = pBO.purchaseid
      var mode=pBO.modetype
      if (!this.isedit) {
        return;
      }
      const modalRef = this.productFindService.open(AddpurchaseComponent, { size: <any>'xl', backdrop: 'static' });
      modalRef.componentInstance.purchaseid = deptid;
      modalRef.componentInstance.filterbranchid = this.filterbranchid;
      modalRef.componentInstance.filterfromdate = this.filterfromdate;
      modalRef.componentInstance.filtertodate = this.filtertodate;
      modalRef.componentInstance.formmode = mode;
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        this.fngetPurchaseListview();

      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }




  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.purchaseid

      if (!this.isdelete) {
        return;
      }
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Purchase?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeletePurchase(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeletePurchase(deptid) {
    var data = {
      'purchaseid': deptid,
    }
    // this.appService.fnDeletePurchase(data)
    //   .subscribe(
    //     (res) => {
    //       if (res.json().status === 200) {
    //         this.toastr.success('', " The Purchase has been deleted successfully.");

    //         this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

    //         this.fngetPurchaseListview();
    //       } else {
    //         this.toastr.error('', res.json().err_field);
    //       }
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fnCurrentRouteChange() {
    var queryParams = {};



    if (this.filterbranchid > 0) {
      queryParams['branchid'] = this.filterbranchid;
    }



    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,

      });
  }


  private fnSetDataTable() {
  
  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.purchaseColl.findIndex(sku => sku.purchaseid === id);

    if (index) {
      return this.purchaseColl[index].taxgroupname;
    }
    else {
      return "";
    }

  }
  public fnSearchDatatable(value) {

  

  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }
  }
  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
