import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-addcompany',
  templateUrl: './addcompany.component.html',
  styleUrls: ['./addcompany.component.css']
})
export class AddcompanyComponent implements OnInit{

  //terms Values
  isaddterms:boolean=false;
  iseditterms:boolean=false;
  isdeleteterms:boolean=false;
  isviewterms:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  countryColl:any=[];

  //Edit Get Values
  companyColl:any=[];
  companyid:number=0
  
  aBO:any=[];

  
  companyform=new FormGroup({     
    companyid:new FormControl(null),     
    companyname:new FormControl('',Validators.required),
    companycode:new FormControl('',Validators.required), 
    address:new FormControl(''), 
    country:new FormControl(''), 
    phone:new FormControl(''), 
    email:new FormControl(''), 
    website:new FormControl(''),         
    logoimgpath: new FormControl(''),
    logoimage: new FormControl(null),
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Company";
  
   //set image
   image;
   strImage: any;
   picurl: string = "";
   picname: string = "";

  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.companyid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddterms=false;
         this.companyform.reset();
         this.fnInitializeCompanyFormGroup(); 
  
         if(this.companyid>0){
           this.fngetCompany();
         }       
         this.fngetCountries();
      
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.companyid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeCompanyFormGroup(){
     this.companyform.setValue({
      companyid:0, 
      companyname:'',   
      companycode:'',  
      address:'',  
      country:'',  
      phone:'',  
      email:'',  
      website:'', 
      logoimgpath: '',
      logoimage: null,  
      inactive:false,      
     })
  }
  
  
  fngetCountries(){
  
    this.appService.getCountries().subscribe((res)=>{
      this.countryColl=res.json();
    })
    }
  
  //Get 
  fngetCompany() {        
       var data = {
         'companyid':this.companyid,            
       }
       this.appService.getCompany(data)
       .subscribe(
         (res)=>{                  
           this.companyColl=res.json().result;
           var editdata =this.companyColl[0];
           this.aBO = editdata;
           this.fnEditterms(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
    
 
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.companyform.valid){
           //Check
           var data = {
                 'companyid': this.companyid,                   
                 'companyname':this.companyform.get('companyname').value,  
                 'companycode' :this.companyform.get('companycode').value,    
                 'address' :this.companyform.get('address').value,   
                 'country' :this.companyform.get('country').value,   
                 'phone' :this.companyform.get('phone').value,   
                 'email' :this.companyform.get('email').value,   
                 'website' :this.companyform.get('website').value,   
                 'logoimage': this.strImage,
                 'logoimgpath': this.picurl,
                 'inactive':this.companyform.get('inactive').value,            
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckCompany(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.companyform.get('companyid').value>0){
                         //Update Department
                         this.fnSetCompany(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertCompany(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.companyform.controls['companyname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertCompany(data){    
   
     this.appService.fnInsertCompany(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new Company has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.companyid);
  
         this.fnCloseCompany();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetCompany(data){    
  
  this.appService.fnSetCompany(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The Company has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.companyid,data.companyname);
  
       this.fnCloseCompany();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseCompany(){
  try{
    this.router.navigate(['/Company']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  fnEditBtnClick() {

    this.iseditterms = true;

    this.isviewterms = false;
  }
  private fnEditterms(deptBO){
  
   this.companyform.setValue({
    companyid:deptBO.companyid,      
    companyname:deptBO.companyname,
    companycode:deptBO.companycode,
    address:deptBO.address,
    country:deptBO.country,
    phone:deptBO.phone,
    email:deptBO.email,
    website:deptBO.website,
    logoimgpath: deptBO.logoimgpath,
    logoimage: null,
    inactive:deptBO.inactive,   
   });
  
  
  this.isaddterms=false;
  this.iseditterms=false;
  this.isdeleteterms=false;
  this.isviewterms=true;
  }
   //Image Import
   changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }


  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{
       
        });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  
  }
  