import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map, share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
      providedIn: 'root'
   }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress: any;

   constructor(private http: Http, private deviceService: DeviceDetectorService) {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.fnGetIpAddress();
      console.log(this.deviceInfo)
   }



   fnpostInputParams(url, params) {
      const headers = new Headers({
         //'Content-Type': "application/JSON",
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)   
         'authkey': (sessionStorage.authkey === undefined) ? 1 : JSON.parse(sessionStorage.authkey),                       
      });


      return this.http.post(AppSettings.base_url + url, params, { headers: headers });
   }





   //Uom 
   fnInsertUom(params) {

      return this.fnpostInputParams(AppSettings.insertUomUrl, params);
   }

   fnSetUom(params) {

      return this.fnpostInputParams(AppSettings.setUomUrl, params);
   }


   getUom(params) {

      return this.fnpostInputParams(AppSettings.getUomUrl, params);
   }


   fnDeleteUom(params) {

      return this.fnpostInputParams(AppSettings.deleteUomUrl, params);
   }

   fnCheckUom(params) {

      return this.fnpostInputParams(AppSettings.checkUomUrl, params);
   }
   //Institute 
   fnInsertInstitute(params) {

      return this.fnpostInputParams(AppSettings.insertInstituteUrl, params);
   }

   fnSetInstitute(params) {

      return this.fnpostInputParams(AppSettings.setInstituteUrl, params);
   }


   getInstitute(params) {

      return this.fnpostInputParams(AppSettings.getInstituteUrl, params);
   }


   fnDeleteInstitute(params) {

      return this.fnpostInputParams(AppSettings.deleteInstituteUrl, params);
   }

   fnCheckInstitute(params) {

      return this.fnpostInputParams(AppSettings.checkInstituteUrl, params);
   }






   //User 
   fnInsertUser(params) {

      return this.fnpostInputParams(AppSettings.insertUserUrl, params);
   }

   fnSetUser(params) {

      return this.fnpostInputParams(AppSettings.setUserUrl, params);
   }


   getUser(params) {

      return this.fnpostInputParams(AppSettings.getUserUrl, params);
   }


   fnDeleteUser(params) {

      return this.fnpostInputParams(AppSettings.deleteUserUrl, params);
   }

   fnCheckUser(params) {

      return this.fnpostInputParams(AppSettings.checkUserUrl, params);
   }

 
    //Family 
    fnInsertFamily(params) {

      return this.fnpostInputParams(AppSettings.insertFamilyUrl, params);
   }

   fnSetFamily(params) {

      return this.fnpostInputParams(AppSettings.setFamilyUrl, params);
   }


   getFamilyListview(params) {

      return this.fnpostInputParams(AppSettings.getFamilyListviewUrl, params);
   }
   getFamily(params) {

      return this.fnpostInputParams(AppSettings.getFamilyUrl, params);
   }


   getFamilyByMemberid(params) {

      return this.fnpostInputParams(AppSettings.getFamilyByMemberidUrl, params);
   }


   getFamilyByHeader(params) {

      return this.fnpostInputParams(AppSettings.getFamilyByHeaderUrl, params);
   }



   fnDeleteFamily(params) {

      return this.fnpostInputParams(AppSettings.deleteFamilyUrl, params);
   }

   fnCheckFamily(params) {

      return this.fnpostInputParams(AppSettings.checkFamilyUrl, params);
   }


   //Country
   getCountries() {
      const headers = new Headers({
         'Content-Type': "application/JSON",
         'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
      });


      return this.http.get("https://api.countrystatecity.in/v1/countries", { headers: headers });

   }
   getState(country) {
      const headers = new Headers({
         'Content-Type': "application/JSON",
         'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
      });


      return this.http.get("https://api.countrystatecity.in/v1/countries/" + country + "/states", { headers: headers });

   }


   //Userrole
   fnInsertUserrole(params) {

      return this.fnpostInputParams(AppSettings.insertUserroleUrl, params);
   }

   fnSetUserrole(params) {

      return this.fnpostInputParams(AppSettings.setUserroleUrl, params);
   }


   getUserrole(params) {

      return this.fnpostInputParams(AppSettings.getUserroleUrl, params);
   }
   getUserRoleRights(params) {

      return this.fnpostInputParams(AppSettings.getUserRoleRightsUrl, params);
   }

   fnDeleteUserrole(params) {

      return this.fnpostInputParams(AppSettings.deleteUserroleUrl, params);
   }

   fnCheckUserrole(params) {

      return this.fnpostInputParams(AppSettings.checkUserroleUrl, params);
   }
   getUserlogin(params) {

      return this.fnpostInputParams(AppSettings.getLoginUserUrl, params);
   }

   //Mohalla 
   fnInsertMohalla(params) {

      return this.fnpostInputParams(AppSettings.insertMohallaUrl, params);
   }

   fnSetMohalla(params) {

      return this.fnpostInputParams(AppSettings.setMohallaUrl, params);
   }


   getMohalla(params) {

      return this.fnpostInputParams(AppSettings.getMohallaUrl, params);
   }


   fnDeleteMohalla(params) {

      return this.fnpostInputParams(AppSettings.deleteMohallaUrl, params);
   }

   fnCheckMohalla(params) {

      return this.fnpostInputParams(AppSettings.checkMohallaUrl, params);
   }
   //Profession 
   fnInsertProfession(params) {

      return this.fnpostInputParams(AppSettings.insertProfessionUrl, params);
   }

   fnSetProfession(params) {

      return this.fnpostInputParams(AppSettings.setProfessionUrl, params);
   }


   getProfession(params) {

      return this.fnpostInputParams(AppSettings.getProfessionUrl, params);
   }


   fnDeleteProfession(params) {

      return this.fnpostInputParams(AppSettings.deleteProfessionUrl, params);
   }

   fnCheckProfession(params) {

      return this.fnpostInputParams(AppSettings.checkProfessionUrl, params);
   }
   //Qualification 
   fnInsertQualification(params) {

      return this.fnpostInputParams(AppSettings.insertQualificationUrl, params);
   }

   fnSetQualification(params) {

      return this.fnpostInputParams(AppSettings.setQualificationUrl, params);
   }


   getQualification(params) {

      return this.fnpostInputParams(AppSettings.getQualificationUrl, params);
   }


   fnDeleteQualification(params) {

      return this.fnpostInputParams(AppSettings.deleteQualificationUrl, params);
   }

   fnCheckQualification(params) {

      return this.fnpostInputParams(AppSettings.checkQualificationUrl, params);
   }

   //Member 
   fnInsertMember(params) {

      return this.fnpostInputParams(AppSettings.insertMemberUrl, params);
   }

   fnSetMember(params) {

      return this.fnpostInputParams(AppSettings.setMemberUrl, params);
   }

   fnSetMemberAddress(params) {

      return this.fnpostInputParams(AppSettings.setMemberAddressUrl, params);
   }

   

   getMember(params) {

      return this.fnpostInputParams(AppSettings.getMemberUrl, params);
   }


   fnDeleteMember(params) {

      return this.fnpostInputParams(AppSettings.deleteMemberUrl, params);
   }

   fnCheckMember(params) {

      return this.fnpostInputParams(AppSettings.checkMemberUrl, params);
   }

    

   fnGetDashboardAdmin(params) {

      return this.fnpostInputParams(AppSettings.getdashboardadminUrl, params);
   }

   
   fnGetReportAll(params) {

      return this.fnpostInputParams(AppSettings.getReportAllUrl, params);
   }

   //Relationship 
   fnInsertRelationship(params) {

      return this.fnpostInputParams(AppSettings.insertRelationshipUrl, params);
   }

   fnSetRelationship(params) {

      return this.fnpostInputParams(AppSettings.setRelationshipUrl, params);
   }


   getRelationship(params) {

      return this.fnpostInputParams(AppSettings.getRelationshipUrl, params);
   }


   fnDeleteRelationship(params) {

      return this.fnpostInputParams(AppSettings.deleteRelationshipUrl, params);
   }

   fnCheckRelationship(params) {

      return this.fnpostInputParams(AppSettings.checkRelationshipUrl, params);
   }

   //City 
   fnInsertCity(params) {

      return this.fnpostInputParams(AppSettings.insertCityUrl, params);
   }

   fnSetCity(params) {

      return this.fnpostInputParams(AppSettings.setCityUrl, params);
   }


   getCity(params) {

      return this.fnpostInputParams(AppSettings.getCityUrl, params);
   }


   fnDeleteCity(params) {

      return this.fnpostInputParams(AppSettings.deleteCityUrl, params);
   }

   fnCheckCity(params) {

      return this.fnpostInputParams(AppSettings.checkCityUrl, params);
   }
   //Street 
   fnInsertStreet(params) {

      return this.fnpostInputParams(AppSettings.insertStreetUrl, params);
   }

   fnSetStreet(params) {

      return this.fnpostInputParams(AppSettings.setStreetUrl, params);
   }


   getStreet(params) {

      return this.fnpostInputParams(AppSettings.getStreetUrl, params);
   }


   fnDeleteStreet(params) {

      return this.fnpostInputParams(AppSettings.deleteStreetUrl, params);
   }

   fnCheckStreet(params) {

      return this.fnpostInputParams(AppSettings.checkStreetUrl, params);
   }


   //Company 
   fnInsertCompany(params) {

      return this.fnpostInputParams(AppSettings.insertCompanyUrl, params);
   }

   fnSetCompany(params) {

      return this.fnpostInputParams(AppSettings.setCompanyUrl, params);
   }


   getCompany(params) {

      return this.fnpostInputParams(AppSettings.getCompanyUrl, params);
   }





   //Tax 
   fnInsertTax(params) {

      return this.fnpostInputParams(AppSettings.insertTaxUrl, params);
   }

   fnSetTax(params) {

      return this.fnpostInputParams(AppSettings.setTaxUrl, params);
   }


   getTax(params) {

      return this.fnpostInputParams(AppSettings.getTaxUrl, params);
   }

   getTaxListView(params) {

      return this.fnpostInputParams(AppSettings.getTaxListViewUrl, params);
   }

   
   getTaxDetailsByFamilyId(params) {

      return this.fnpostInputParams(AppSettings.getTaxDetailsByFamilyIdUrl, params);
   }

   fnDeleteTax(params) {

      return this.fnpostInputParams(AppSettings.deleteTaxUrl, params);
   }

   fnCheckTax(params) {

      return this.fnpostInputParams(AppSettings.checkTaxUrl, params);
   }








   // //Country
   // getCountries() {
   //    const headers = new Headers({
   //       'Content-Type': "application/JSON",
   //       'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
   //       // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
   //    });


   //    return this.http.get("https://api.countrystatecity.in/v1/countries", { headers: headers });

   // }
   // getState(country) {
   //    const headers = new Headers({
   //       'Content-Type': "application/JSON",
   //       'X-CSCAPI-KEY': "cmZXSktRU2h5QWpYa1JnZVhYSldmUmhFc1pDZEZ2WDV5ZzVYMkQzRA=="
   //       // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)                
   //    });


   //    return this.http.get("https://api.countrystatecity.in/v1/countries/"+country+"/states", { headers: headers });

   // }

   fnDeleteCompany(params) {

      return this.fnpostInputParams(AppSettings.deleteCompanyUrl, params);
   }

   fnCheckCompany(params) {

      return this.fnpostInputParams(AppSettings.checkCompanyUrl, params);
   }


   //Common
   fnGetIpAddress() {
      this.http.get('https://jsonip.com')
         .subscribe(data => {
            this.ipAddress = data.json()

         })

   }

   fnInsertActivity(data) {

      var dept_para = {
         logtype: data.logtype,
         logat: data.logat,
         refnum: data.refnum,
         logdescr: data.logdescr,
         logby: data.logby,
         ipaddress: this.ipAddress.ip,
         devicename: this.deviceInfo.browser + " " + this.deviceInfo.os,
      };


      return this.fnpostInputParams(AppSettings.insertActivitylogUrl, dept_para);
   }

}


