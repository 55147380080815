import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-adduom',
  templateUrl: './adduom.component.html',
  styleUrls: ['./adduom.component.css']
})
export class AdduomComponent implements OnInit  {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  uomColl:any=[];
  uomid:number=0
  

  
  uomform=new FormGroup({     
    uomid:new FormControl(null),     
    uomname:new FormControl('',Validators.required),            
    inactive:new FormControl(false),     
  });
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Uom";
  
  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.uomid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.uomform.reset();
         this.fnInitializeUomFormGroup(); 
  
         if(this.uomid>0){
           this.fngetUom();
         }       
  
      
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.uomid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeUomFormGroup(){
     this.uomform.setValue({
      uomid:0, 
      uomname:'',    
      inactive:false,      
     })
  }
  
  
  
  
  //Get 
  fngetUom() {        
    var data = {
      'uomid':this.uomid,            
    }
    this.appService.getUom(data)
    .subscribe(
      (res)=>{                  
        this.uomColl=res.json().result;
        var editdata =this.uomColl[0];
        this.fnEditMode(editdata);
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })
}
  
  
    
 
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.uomform.valid){
           //Check
           var data = {
                 'uomid': this.uomid,                   
                 'uomname':this.uomform.get('uomname').value,            
                 'inactive':this.uomform.get('inactive').value,            
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckUom(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.uomform.get('uomid').value>0){
                         //Update Department
                         this.fnSetUom(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertUom(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.uomform.controls['uomname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertUom(data){    
   
     this.appService.fnInsertUom(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new Uom has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.uomid);
  
         this.fnCloseUom();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetUom(data){    
  
  this.appService.fnSetUom(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The Uom has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.uomid,data.uomname);
  
       this.fnCloseUom();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseUom(){
  try{
    this.router.navigate(['/Uom']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  private fnEditMode(deptBO){
  
   this.uomform.setValue({
    uomid:deptBO.uomid,      
    uomname:deptBO.uomname,
        inactive:deptBO.inactive,   
   });
  
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  
  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{
       
      //   });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  
  }
  