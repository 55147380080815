import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginDataService } from '../services/dhukan/login-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  constructor(public router: Router,private loginService: LoginDataService) {
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO= this.userView.userBO                
        }      
        
    }); 
  }

  ngOnInit() {
    
    if(sessionStorage.rmstoken === undefined){
      this.router.navigate(['/Login']);
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

   
  }
  fnCheckMenu() {

    // if (this.loginService.fnCheckUserRoleAll('Company')) {
    //   this.iscompany = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('Branch')) {
    //   this.isbranch = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('Counter')) {
    //   this.iscounter = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('PaymentType')) {
    //   this.ispaymenttype = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('ReceiptType')) {
    //   this.isreceipttype = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('Currency')) {
    //   this.iscurrency = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('User')) {
    //   this.isuser = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('Userrole')) {
    //   this.isuserrole = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('CounterEOD')) {
    //   this.iscountereod = true;
    // }
    // if (this.loginService.fnCheckUserRoleAll('EOD')) {
    //   this.iseod = true;
    // }
    
    // if (this.loginService.fnCheckUserRoleAll('EODReport')) {
    //   this.iseodreport = true;
    // }


    // if (this.iscompany || this.isbranch || this.iscounter || this.ispaymenttype || this.isreceipttype || this.iscurrency || this.isuser || this.isuserrole ) {
    //   this.ismastermenu = true;
    // }
    
  }

  
  
  fnLogout(){
    sessionStorage.removeItem("eodadtoken");
    this.router.navigate(['/Login']);
  }

}



