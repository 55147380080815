import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Http,Headers } from '@angular/http';
import { Router } from '@angular/router';
import { AppSettings } from '../constants/constants';
import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class LoginDataService {

  public islogin:boolean=false;
  
  serviceloginChange: Subject<boolean> = new Subject<boolean>();


  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  constructor(private http: Http, public router: Router, private confirmationDialogService: ConfirmationDialogService) { }


  fnpostInputParams(url, params) {
    const headers = new Headers({    
        //'Content-Type':  "application/JSON",
        'token': (sessionStorage.rmstoken === undefined) ? '' : sessionStorage.rmstoken               
    });

    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }
  
  


  //Get UserRole List
  fnGetUserRoleList(){  
  
    var data = {
      'userid':0,                          
    }

    this.fnpostInputParams(AppSettings.getUserRoleRightsUserUrl,data)
    .subscribe(
      (res)=>{               
      
        if(res.json().status==200){  
          var _res =res.json().result; 
         
          if(_res.length){

            if(_res[0].length>0){
              this.userBO=_res[0][0];
          
            }
            else{
              this.userBO={};
            }
            
            this.userrolerightsColl=_res[1];
          }

          this.userView={
              userBO:this.userBO,
              userrolerightsColl:this.userrolerightsColl
          }
          

          this.serviceloginChange.next( this.userView);
        }
        else if(res.json().status==400){                          
          this.fnShowErrorMsg('Error'+res.json().err_field);
        }          
      },
      (err)=>{          

        this.fnShowErrorMsg('Error'+ err);
      })

  }

      
  

  fnCheckUser(){
    
    if( this.userBO.userid){
      return true;
    }
    else if(sessionStorage.rmstoken !== undefined){
      return false;
    }
    else{
      return false;
    }

  }
  fnCheckLogin() {
    if (sessionStorage.rmstoken === undefined) {
      this.router.navigate(['/Login']);
    }

  }
  
  fnGetModUserId(){
    if( this.userBO.userid){
      return this.userBO.userid;
    }
    else{
      return 0;
    }
  }

  fnUserFaildRedirect(){
    if(!this.userBO){
      this.router.navigate(['/Login']);
    }
  }

  fnCheckAdminUser(){
    
    if( this.userBO.userid){
      return this.userBO.isadmin;
    }
    else{
      return 0;
    }
  }


  fnCheckUserRoleAll(formname){
   

    if( this.userrolerightsColl){
     
      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname  ); 
      if(index>-1){ 
          var _pBO=this.userrolerightsColl[index];
         
          if(_pBO.uappcreate || _pBO.uappalter || _pBO.uappdelete  || _pBO.uappview || _pBO.uappprint || _pBO.uappexport ){
            return true;
          }
          else{
            return false;
          }
      }
    }else{
      return false;
    }
  }
  
  fnCheckUserRoleAction(formname,action){
 
    
    if( this.userrolerightsColl){

      const index = this.userrolerightsColl.findIndex(abo => abo.userappformname === formname  ); 
      if(index>-1){         
          var _pBO=this.userrolerightsColl[index];

          if(action==='CREATE' && _pBO.uappcreate){
            return true;
          }
          else if(action==='ALTER' && _pBO.uappalter){
            return true;
          }
          else if(action==='DELETE' && _pBO.uappdelete){
            return true;
          }
          else if(action==='VIEW' && _pBO.uappview){
            return true;
          }
          else if(action==='PRINT' && _pBO.uappprint){
            return true;
          }
          else if(action==='EXPORT' && _pBO.uappexport){
            return true;
          }
          else{
            return false;
          }
      }
      else{
        return false;
      }
      
    }else{
      return false;
    }
  }

  fnCheckFornControlNotfound(fc) {
    let ischeck = false;
    const validator = fc;
      
    if (validator.errors) {
      if (validator.errors.notfound) {
        ischeck = true;
      }         
    }

    return ischeck;
  }  
  fnCompareDateValidation(fromdate, todate) {
    if (Date.parse(fromdate.toDateString()) < Date.parse(todate.toDateString())) {
      return true
    }
    else {
      return false
    }

  }

  
  fnSetFirstControlFocus(controlnanme) {
    try{
      setTimeout(() => {
       document.getElementById(controlnanme).focus();  
      }, 500);
      
    }
    catch{

    }

  }

  fnSetFirstControlFocusLookup(ngselect) {
    try {
      if (ngselect) {
        setTimeout(() => {
          ngselect.focus();  
        }, 500);
        
      }
     
    }
    catch{

    }

  }

  fnDecimalBlur(event) {    
    if (event.target.value !== '') {
     
      event.target.value = parseFloat(event.target.value).toFixed(2)
 
    }
  }

  
  fnCheckZeroDetcimal(value) { 
    
    if (value === '' || value === null) {     
      return 0   
    }
    else{
      return Number(value)
    }
  }


  fnConfirmFormLeave(): Promise<boolean> {

    return this.confirmationDialogService.confirm('Confirm..', 'Do you  want to leave this page?')
      .then((confirmed) => {
        if (confirmed) {
          return true;
        }
        else {
          return false
        }

      }
      );


  }
  
  

  fnDatePickerOpen(datepicker) {
    if (datepicker) {

      datepicker.toggle();

    }
  }


  fnDatePickerClose(event, datepicker) {
  
    if (event.relatedTarget) {
      if (event.relatedTarget.parentNode.nodeName !== 'BS-DATEPICKER-NAVIGATION-VIEW') {
        datepicker.hide();
      }
    }
  }


 private  fnShowErrorMsg(ex){
  console.log(ex);       
}



}
