import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addmohalla',
  templateUrl: './addmohalla.component.html',
  styleUrls: ['./addmohalla.component.css']
})
export class AddmohallaComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  mohallaColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Mohalla";

  mohallaform = new FormGroup({
    mohallaid: new FormControl(null),
    mohallaname: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });


  @Input() mohallaid: number = 0;
  @Input() mohallaname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.mohallaid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.mohallaform.reset();
      this.fnInitializeMohallaFormGroup();

      if (this.mohallaid > 0) {
        this.fngetMohalla();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.mohallaid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeMohallaFormGroup() {
    this.mohallaform.setValue({
      mohallaid: 0,
      mohallaname: '',
      inactive: false,
    })
  }





  //Get 
  fngetMohalla() {
    var data = {
      'mohallaid': this.mohallaid,
    }
    this.appService.getMohalla(data)
      .subscribe(
        (res) => {
          this.mohallaColl = res.json().result;
          var editdata = this.mohallaColl[0];
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.mohallaform.valid) {
        //Check
        var data = {
          'mohallaid': this.mohallaid,
          'mohallaname': this.mohallaform.get('mohallaname').value,
          'inactive': this.mohallaform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckMohalla(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.mohallaform.get('mohallaid').value > 0) {
                //Update Department
                this.fnSetMohalla(data);
              }
              else {
                //Insert Department
                this.fnInsertMohalla(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.mohallaform.controls['mohallaname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertMohalla(data) {

    this.appService.fnInsertMohalla(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var mohallaid = 0;
          if (result.length > 0) {
            mohallaid = result[0].mohallaid
          }

          this.fnInsertActivityLog('INSERT', 0, mohallaid);

          this.toastr.success('', "The new Mohalla has been added successfully.Insert Successfully");
          this.emitData.emit(mohallaid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetMohalla(data) {

    this.appService.fnSetMohalla(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Mohalla has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.mohallaid, data.mohallaname);

          this.emitData.emit(this.mohallaid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.mohallaform.setValue({
      mohallaid: deptBO.mohallaid,
      mohallaname: deptBO.mohallaname,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{

        });

    } catch {

    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
