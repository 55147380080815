import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exportgrid',
  templateUrl: './exportgrid.component.html',
  styleUrls: ['./exportgrid.component.css']
})
export class ExportgridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
