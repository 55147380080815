import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationDialogService } from 'src/app/components/confirmation-dialog/confirmation-dialog.service';

import { ComponentCanDeactivate } from 'src/app/components/PendingChangesGuard';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-addpurchase',
  templateUrl: './addpurchase.component.html',
  styleUrls: ['./addpurchase.component.css']
})
export class AddpurchaseComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('Selecter', { static: false }) ngselect: NgSelectComponent;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;
  ischeck: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isproductaddmode: boolean = false;
  ispartyaddmode: boolean = false;

  rtnform: string = "";
  errtxt :any="";

  //Edit Get Values
  purchasemainColl: any = [];

  purchaseBO: any = {}
  branchColl: any = [];
  partyColl: any = [];
  paymenttermsColl: any = [];
  paymentmodeColl: any = [];

  purchasedetailscoll: any = [];

  ismanualclose: boolean;

  isaccountaddmode: boolean = false;


  qtytotal: number = 0;
  unitpricetotal: number = 0;
  subtotal: number = 0;
  taxtotal: number = 0;
  cgsttaxtotal: number = 0;
  sgsttaxtotal: number = 0;
  igsttaxtotal: number = 0;
  nettotal: number = 0;

  discounttype: string = "";

  productfindColl: any;
  taxColl: any;
  productColl: any;
  isLoadingResult: boolean;

  filterpaymentmodeid: number = 0;

  purchaseform = new FormGroup({
    purchaseid: new FormControl(0),
    purchaseno: new FormControl('', Validators.required),
    purchasedate: new FormControl(null),
    branchid: new FormControl(null, Validators.required),
    grandtotal: new FormControl(null),
    descr: new FormControl(''),
    partyid: new FormControl(null, Validators.required),
    refno: new FormControl(''),
    invoiceno: new FormControl('', Validators.required),
    duedate: new FormControl(null, Validators.required),
    paymentmodeid: new FormControl(null, Validators.required),
    paymenttermsid: new FormControl(null, Validators.required),
    remarks: new FormControl(''),
    discrate: new FormControl(null),
    disamt: new FormControl(null),
    roundoff: new FormControl(null),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Purchase";

  gridfocusrow: number = -1;

  @Input() purchaseid: number = 0
  @Input() filterbranchid: number = 0;
  @Input() filterfromdate: any = null;
  @Input() filtertodate: any = null;
  @Input() ptype: String = "S";
  @Input() formmode: String = "";


  @Output() emitData = new EventEmitter();

  constructor(private appService: DhukanDataService, private activeModal: NgbActiveModal, private route: ActivatedRoute,
    private productFindService: NgbModal, private confirmationDialogService: ConfirmationDialogService,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe) {

    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.purchaseform.reset();
      this.fnInitializeForm();

      if (this.purchaseid > 0) {
        this.fngetPurchase();
      }
      else {
        this.fnSetFirstRowItem()
      }

      this.fngetBranch();
      this.fngetParty();
      this.fngetPaymentterms();
      this.fngetPaymentmode();

      this.fnGetProduct()

      this.fnGetTax()



      this.fnSetInitialise()



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    this.loginService.fnSetFirstControlFocusLookup(this.ngselect);


  }

  fnSetInitialise() {

    this.purchaseform.controls["branchid"].setValue(Number(this.filterbranchid));
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.purchaseid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }
    this.isproductaddmode = this.loginService.fnCheckUserRoleAction("Product", 'CREATE')
    this.ispartyaddmode = this.loginService.fnCheckUserRoleAction("Party", 'CREATE')

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeForm() {
    this.purchaseform.setValue({
      purchaseid: 0,
      purchaseno: 'NEW',
      purchasedate: new Date(),
      branchid: null,
      grandtotal: null,
      descr: null,
      partyid: null,
      refno: '',
      invoiceno: '',
      duedate: new Date(),
      paymentmodeid: null,
      paymenttermsid: null,
      remarks: '',
      discrate: null,
      disamt: null,
      roundoff: null,
      inactive: false,
    })
  }
  fngetPaymentterms() {

    var data = {
      'paymenttermsid': 0,
    }
    // this.appService.getPaymentterms(data)
    //   .subscribe(
    //     (res) => {
    //       this.paymenttermsColl = res.json().result;

    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })

  }

  fngetPaymentmode() {
    var data = {
      'paymentmodeid': 0,
    }
    // this.appService.getPaymentmode(data)
    //   .subscribe(
    //     (res) => {
    //       this.paymentmodeColl = res.json().result;


    //       if (this.purchaseid === 0 && this.paymentmodeColl.length > 0) {
    //         for (let abo of this.paymentmodeColl) {
      
    //           if (abo.paymenttype === 'CREDIT') {
    //             this.purchaseform.patchValue({
    //               'paymentmodeid': abo.paymentmodeid
    //             })
    //           }
    //         }
    //       }


    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fngetBranch() {
    var data = {
      'branchid': 0,
    }
    // this.appService.getBranch(data)
    //   .subscribe(
    //     (res) => {
    //       this.branchColl = res.json().result;
    //       this.filterbranchid = this.branchColl.branchid;
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fngetParty() {
    var data = {
      'partyid': 0,
      'ptype': this.ptype,
    }
    // this.appService.getParty(data)
    //   .subscribe(
    //     (res) => {
    //       this.partyColl = res.json().result;


    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }




  //Get 
  fngetPurchase() {
    var data = {
      'purchaseid': this.purchaseid,
    }
    // this.appService.getPurchase(data)
    //   .subscribe(
    //     (res) => {
    //       var result = res.json().result;

    //       this.purchasemainColl = result[0];

    //       var editdata = this.purchasemainColl[0];
    //       this.purchaseBO = editdata;
    //       this.fnEditMode(editdata);

    //       this.purchasedetailscoll = result[1];

    //       if (this.purchasedetailscoll.length === 0) {
    //         this.fnSetFirstRowItem()
    //       }
    //       this.fnCalcDetails();
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fnValidation() {
    var _ischeck = false;
    var _detailscheck = false;

    for (let row of this.purchasedetailscoll) {
      if (row.productid > 0) {
        _detailscheck = true;
      }
    }

    if (!_detailscheck) {
      this.errtxt="Purchase Details cannot be empty"
      this.purchaseform.controls['inactive'].setErrors({ 'notfound': true });
      _ischeck = true;
    }
    if (!_ischeck) {
      for (let row of this.purchasedetailscoll) {       
        console.log(this.loginService.fnCheckZeroDetcimal(row.qty))
      

        if (row.productid  > 0 && this.loginService.fnCheckZeroDetcimal(row.qty)===0 ) {
             this.errtxt = "Quantity is cannot be empty"
          this.purchaseform.controls['inactive'].setErrors({ 'notfound': true });
          _ischeck = true;
        }
      
      }
    }
    return _ischeck;

  }

  fnResetValidation() {

    if (this.purchaseform.get('inactive')) {
      this.purchaseform.controls['inactive'].setErrors(null);
    }

  }


  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;

      this.fnResetValidation()
      //Validate
      if (this.purchaseform.valid) {

        if (this.fnValidation()) {
          return false
        }

        //Check
        var data = {
          'purchaseid': this.purchaseid,
          'purchaseno': this.purchaseform.get('purchaseno').value,
          'purchasedate': this.fnFormatDatetime(this.purchaseform.get('purchasedate').value),
          'branchid': this.purchaseform.get('branchid').value,
          'grandtotal': this.purchaseform.get('grandtotal').value,
          'descr': this.purchaseform.get('descr').value,
          'partyid': this.purchaseform.get('partyid').value,
          'refno': this.purchaseform.get('refno').value,
          'invoiceno': this.purchaseform.get('invoiceno').value,
          'duedate': this.fnFormatDatetime(this.purchaseform.get('duedate').value),
          'paymentmodeid': this.purchaseform.get('paymentmodeid').value,
          'paymenttermsid': this.purchaseform.get('paymenttermsid').value,
          'remarks': this.purchaseform.get('remarks').value,
          'discrate': this.purchaseform.get('discrate').value,
          'disamt': this.purchaseform.get('disamt').value,
          'roundoff': this.purchaseform.get('roundoff').value,
          'inactive': this.purchaseform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'purchasedetailscoll': this.purchasedetailscoll,
        }

        if (this.purchaseform.get('purchaseid').value > 0) {

          this.fnSetPurchase(data);
        }
        else {

          this.fnInsertPurchase(data);
        }

      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertPurchase(data) {

    // this.appService.fnInsertPurchase(data).subscribe(
    //   (res) => {
    //     if (res.json().status == 200) {
    //       this.toastr.success('', "The new Purchase has been added successfully.Insert Successfully");

    //       this.fnInsertActivityLog('INSERT', 0, data.purchaseno);

    //       var _result = res.json().result;
    //       if (_result.length > 0) {
    //         this.fnEditFormEdit(_result[0].purchaseid);
    //       }

    //     } else {
    //       this.toastr.error('Error', res.json().err_message);
    //     }
    //   },
    //   (err) => {
    //     this.toastr.error('Error', err);
    //   });

  }

  //Set
  fnSetPurchase(data) {

    // this.appService.fnSetPurchase(data).subscribe(
    //   (res) => {
    //     if (res.json().status == 200) {
    //       this.toastr.success('', "The Purchase has been updated successfully.");

    //       this.fnInsertActivityLog('EDIT', this.purchaseid, data.purchaseno);

    //       this.fnEditFormEdit(this.purchaseid);
    //     } else {
    //       this.toastr.error('Error', res.json().err_message);
    //     }
    //   },
    //   (err) => {
    //     this.toastr.error('Error', err);
    //   });

  }

  private fnEditMode(deptBO) {

    deptBO.purchasedate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.purchasedate));
    deptBO.duedate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.duedate));

    this.purchaseform.setValue({

      purchaseid: deptBO.purchaseid,
      purchaseno: deptBO.purchaseno,
      purchasedate: deptBO.purchasedate,
      branchid: deptBO.branchid,
      grandtotal: deptBO.grandtotal,
      descr: deptBO.descr,
      partyid: deptBO.partyid,
      refno: deptBO.refno,
      invoiceno: deptBO.invoiceno,
      duedate: deptBO.duedate,
      paymentmodeid: deptBO.paymentmodeid,
      paymenttermsid: deptBO.paymenttermsid,
      remarks: deptBO.remarks,
      discrate: deptBO.discrate,
      disamt: deptBO.disamt,
      roundoff: deptBO.roundoff,
      inactive: deptBO.inactive,

    });


    //Bill Discount
    if (deptBO.discrate === 0 && deptBO.disamt > 0) {
      this.discounttype= "AMT"  
    }
    

    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = false;

    if (this.formmode === 'EDIT') {
      this.iseditmode = true;
    }
    else {
      this.isviewmode = true;
    }
  }


  fnEditBtnClick() {
    this.iseditmode = true;

    this.isviewmode = false;
  }
  fnChangePaymentterms() {
    var _paymenttermsid: number = 0;
    for (let row of this.partyColl) {
      if (row.partyid === this.purchaseform.get('partyid').value) {
        _paymenttermsid = row.paymenttermsid
      }
    }
    this.purchaseform.controls['paymenttermsid'].setValue(_paymenttermsid);
  }


  fnChangeDueDate() {
    var _duedate = new Date();

    var _purchasedt = this.purchaseform.get('purchasedate').value;
   
    var _countdays: number = 0;

    for (let row of this.paymenttermsColl) {
      if (row.paymenttermsid === this.purchaseform.get('paymenttermsid').value) {
        _countdays = row.paydays
      }
    }

   
    _duedate.setDate(_purchasedt.getDate() + _countdays);

    this.purchaseform.controls['duedate'].setValue(_duedate);
  }


  fnEditFormEdit(id) {

    this.purchaseid = id;
    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;


    //this.ngOnInit();

    this.ismanualclose = true;
    this.fnCloseForm();

  }

  //Close
  async fnCloseForm() {

    try {

      if ((this.isaddmode || this.iseditmode) && !this.ismanualclose) {
        var a = await this.loginService.fnConfirmFormLeave();
        if (a === false) {
          return false;
        }
      }

      this.ismanualclose = true;


      this.emitData.emit("");
      this.activeModal.close(true);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  @HostListener('window:beforeunload')
  canDeactivate(): any {

    if (this.ismanualclose || this.isviewmode) {
      return true;
    }
    else {
      return false;
    }
  }

  //Details

  fnGetProduct() {
    this.isLoadingResult = true;

    var data = {
      'productid': 0,
    }
    // this.appService.getProduct(data)
    //   .subscribe(
    //     (res) => {
    //       this.productColl = res.json().result;
        

    //       this.isLoadingResult = false;
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }
  fnReLoadProduct(index) {


    var data = {
      'productid': 0,
    }
    // this.appService.getProduct(data)
    //   .subscribe(
    //     (res) => {
    //       this.productColl = res.json().result;

    //       this.fnChangeProduct(index)
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fnGetTax() {
    this.isLoadingResult = true;

    var data = {
      'taxid': 0,
    }
    // this.appService.getTax(data)
    //   .subscribe(
    //     (res) => {
    //       this.taxColl = res.json().result;


    //       this.isLoadingResult = false;
    //     },
    //     (err) => {
    //       this.toastr.error('Error', err);
    //     })
  }

  fnCalcDetails() {

    for (let row of this.purchasedetailscoll) {
      row.subtotal = row.qty * row.unitprice;

      row.taxamt = row.subtotal * row.taxrate / 100

      //GST      
      row.cgsttaxamt = Number(this.fnConvertDecimal(row.taxamt)) / 2
      row.sgsttaxamt = Number(this.fnConvertDecimal(row.taxamt)) / 2
      row.igsttaxamt = 0


      row.taxable = Number(this.fnConvertDecimal(row.subtotal));


      row.nettotal = Number(this.fnConvertDecimal(row.taxable)) + Number(this.fnConvertDecimal(row.taxamt))
    }





    this.fnCalcsSummaryTotal()
  }




  fnCalcsSummaryTotal() {
    this.qtytotal = 0;
    this.unitpricetotal = 0;
    this.subtotal = 0;
    this.taxtotal = 0;
    this.cgsttaxtotal = 0;
    this.sgsttaxtotal = 0;
    this.igsttaxtotal = 0;
    this.nettotal = 0;

    for (let row of this.purchasedetailscoll) {
      this.qtytotal = Number(this.fnConvertDecimal(this.qtytotal)) + Number(this.fnConvertDecimal(row.qty))
      this.unitpricetotal = Number(this.fnConvertDecimal(this.unitpricetotal)) + row.unitprice
      this.subtotal = Number(this.fnConvertDecimal(this.subtotal)) + row.subtotal

      this.taxtotal = Number(this.fnConvertDecimal(this.taxtotal)) + row.taxamt
      this.cgsttaxtotal = Number(this.fnConvertDecimal(this.cgsttaxtotal)) + row.cgsttaxamt
      this.sgsttaxtotal = Number(this.fnConvertDecimal(this.sgsttaxtotal)) + row.sgsttaxamt
      this.igsttaxtotal = Number(this.fnConvertDecimal(this.igsttaxtotal)) + row.igsttaxamt

      this.nettotal = Number(this.fnConvertDecimal(this.nettotal)) + row.nettotal

    }

    //Bill Discount
    var discrate = 0, disamt = 0;
    var billdiscamt = 0;
    discrate = this.purchaseform.get("discrate").value;
    disamt = this.purchaseform.get("disamt").value;


    if (this.discounttype === 'AMT') {
      discrate=0
      this.purchaseform.patchValue({ 'discrate': discrate });
      billdiscamt=Number(this.fnConvertDecimal(disamt))
    }
    else {     
      billdiscamt = Number(this.fnConvertDecimal(Number(this.fnConvertDecimal(this.nettotal)) * Number(this.fnConvertDecimal(discrate)) / 100))       
      this.purchaseform.patchValue({ 'disamt': billdiscamt });
    }

    this.nettotal=this.nettotal-Number(this.fnConvertDecimal(billdiscamt))



    //Roundoff
    var roundoff = 0;
    roundoff = this.purchaseform.get("roundoff").value;

    this.nettotal=this.nettotal+Number(this.fnConvertDecimal(roundoff))



    this.purchaseform.patchValue({ grandtotal: this.nettotal })
  }


  fnDeletePurchaseConfirm(index) {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete details?')
        .then((confirmed) => {
          if (confirmed) {
            this.purchasedetailscoll.splice(index, 1)
            if (this.purchasedetailscoll.length === 0) {
              this.fnSetFirstRowItem()
            }
            this.fnCalcDetails()
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnChangeProduct(index) {

    var _prodname = "", _uomid = null, _unitprice = 0, _taxid = 0;

    for (let abo of this.productColl) {
      if (this.purchasedetailscoll[index].productid === abo.productid) {
        _prodname = abo.productname;
        _uomid = abo.uomid;
        _unitprice = abo.sellingprice;
        _taxid = abo.taxid;
      }
    }
    this.purchasedetailscoll[index].productname = _prodname;
    this.purchasedetailscoll[index].uomid = _uomid;
    this.purchasedetailscoll[index].taxid = _taxid;
    this.purchasedetailscoll[index].unitprice = _unitprice;

    this.fnChangeTax(index)

  }



  fnChangeTax(index) {

    var _taxname = "", _taxrate = 0;

    var _cgsttaxrate = 0, _sgsttaxrate = 0, _igsttaxrate = 0;

    for (let abo of this.taxColl) {
      if (this.purchasedetailscoll[index].taxid === abo.taxid) {
        _taxname = abo.taxname;
        _taxrate = abo.taxrate;
        _cgsttaxrate = abo.cgsttaxrate;
        _sgsttaxrate = abo.sgsttaxrate;
        _igsttaxrate = abo.igsttaxrate;
      }
    }
    this.purchasedetailscoll[index].taxname = _taxname;
    this.purchasedetailscoll[index].taxrate = _taxrate;
    this.purchasedetailscoll[index].cgsttaxrate = _cgsttaxrate;
    this.purchasedetailscoll[index].sgsttaxrate = _sgsttaxrate;
    this.purchasedetailscoll[index].igsttaxrate = _igsttaxrate;

    this.fnCalcDetails()

  }


  fnChangeDiscountType(type) {
    this.discounttype = type;

    if (this.discounttype === "AMT") {
      this.purchaseform.patchValue({ 'discrate': 0 });
      this.purchaseform.patchValue({ 'disamt': 0 });
      this.fnCalcDetails();
    }

  }


  fnSetFirstRowItem() {
    if (this.purchasedetailscoll.length > 0) {
      if (!this.purchasedetailscoll[this.purchasedetailscoll.length - 1].productid) {
        return
      }
    }

    var data = {
      purchasedetailsid: 0,
      purchaseid: 0,
      productid: null,
      productname: '',
      uomid: null,
      qty: 0,
      unitprice: 0,
      subtotal: 0,
      taxid: null,
      taxname: '',
      taxrate: 0,
      taxamt: 0,
      cgsttaxrate: 0,
      cgsttaxamt: 0,
      sgsttaxrate: 0,
      sgsttaxamt: 0,
      igsttaxrate: 0,
      igsttaxamt: 0,
    }

    this.purchasedetailscoll.push(data)

  }


  //Focus
  fnFocusChangeGridRow(index) {
    this.gridfocusrow = index
  }

  fnSetNextFocus(index) {

    if (this.purchasedetailscoll.length > 0) {

      if (this.purchasedetailscoll.length > index + 1) {
        this.gridfocusrow = index + 1
      }
    }


  }


  //AddParty
  private fnAddParty() {

    // const modalRef = this.productFindService.open(AddpartypopComponent, { size: <any>'l' });
    // modalRef.componentInstance.ptype = this.ptype;
    // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    //   this.fngetParty();

    //   if (receivedEntry > 0) {
    //     this.purchaseform.patchValue({ partyid: receivedEntry })
    //   }
    // });

  }
  //AddProduct
  private fnAddProduct(index) {

    // const modalRef = this.productFindService.open(AddproductpopComponent, { size: <any>'l' });
    // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    //   this.fnGetProduct();

    //   if (receivedEntry > 0) {
    //     this.purchasedetailscoll[index].productid = receivedEntry
    //   }
    //   this.fnReLoadProduct(index);
    
    // });
  }
 //Print
 private fnPrintPurchase() {

  // const modalRef = this.productFindService.open(PrintComponent, { size: <any>'xxxxl', backdrop: 'static' });
  // modalRef.componentInstance.trxid = this.purchaseid;
  // modalRef.componentInstance.doctype = this.formname;

  // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


   
  // });

}


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }

  fnConvertDecimal(value) {
    if (!value) {
      return 0
    }
    else {
      return value
    }

  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }




  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
