import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
@Component({
  selector: 'app-adduserrole',
  templateUrl: './adduserrole.component.html',
  styleUrls: ['./adduserrole.component.css']
})
export class AdduserroleComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  
  
  //Edit Get Values
  userroleColl:any=[];
  userroleid:number=0
  
  urolerightsColl:any=[];
  //pageColl:any=[];
  
  userroleform=new FormGroup({        
    userroleid:new FormControl(0),     
    //productgroup:new FormControl('',Validators.required),            
    userrolename:new FormControl('',Validators.required),   
    inactive:new FormControl(false),     
  });


  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Userrole";

  private gridApi;
  private gridColumnApi;

  
  rowGroupPanelShow = "always";
  columnDefs = [
    // { headerName: 'Module Name', field: 'modulename'},
    { headerName: 'User Form Name', field: 'userappformname', sortable: true, filter: true },    
    // { headerName: 'All',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappall',
    //       cellRenderer: function (params) {           
    //         let operatorValue = params.value;
    //         const input = document.createElement('input');input.type = 'checkbox';
    //          if (operatorValue) {
    //            input.checked = true; params.data.uappall = true;
    //          } else {
    //            input.checked = false;params.data.uappall = false;
    //          }
    //          input.addEventListener('click', function (event) {        
    //            input.checked != input.checked;
    //            params.data.uappall = input.checked;
    //            params.data.uappcreate = input.checked;
    //            params.data.uappalter = input.checked;
    //            params.data.uappview = input.checked;
    //            params.data.uappprint = input.checked;
    //            params.data.uappexport = input.checked;
             
    //          });
    //          return input;        
    //     }
    // },   
    { headerName: 'Create',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappcreate',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappcreate = true;
             } else {
               input.checked = false;params.data.uappcreate = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappcreate = input.checked;
             });
             return input;        
        }
    },   
    { headerName: 'Alter',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappalter',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappalter = true;
             } else {
               input.checked = false;params.data.uappalter = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappalter = input.checked;
             });
             return input;        
        }
    },   
    { headerName: 'Delete',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappdelete',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappdelete = true;
             } else {
               input.checked = false;params.data.uappdelete = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappdelete = input.checked;
             });
             return input;        
        }
    },   
    { headerName: 'View',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappview',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappview = true;
             } else {
               input.checked = false;params.data.uappview = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappview = input.checked;
             });
             return input;        
        }
    },   
    { headerName: 'Print',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappprint',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappprint = true;
             } else {
               input.checked = false;params.data.uappprint = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappprint = input.checked;
             });
             return input;        
        }
    },   


    { headerName: 'Export',checkboxSelection: false,headerCheckboxSelection: false,filter: false,sortable: false,field: 'uappexport',
          cellRenderer: function (params) {           
            let operatorValue = params.value;
            const input = document.createElement('input');input.type = 'checkbox';
             if (operatorValue) {
               input.checked = true; params.data.uappexport = true;
             } else {
               input.checked = false;params.data.uappexport = false;
             }
             input.addEventListener('click', function (event) {        
               input.checked != input.checked;
               params.data.uappexport = input.checked;
             });
             return input;        
        }
    },   
  ]
  
 

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 30,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };


  
 
  
  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.userroleid = params.id
        }  
       });
       this.fnServiceChanges();
  }
  
  
  fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.userroleform.reset();
         this.fnInitializeUserrole(); 
  
         if(this.userroleid>0){
           this.fngetUserrole();
         }       
  this. fngetUserRoleRights() ;
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
    //Grid
    fnGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  
  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.userroleid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  
  
  fnInitializeUserrole(){
     this.userroleform.setValue({
       
      userroleid:0, 
      userrolename:'',    
      inactive:false,      
     })
  }
  fnSetTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }
  fngetUserRoleRights() {
   
    var data = {
      'userroleid': this.userroleid,
    }
    this.appService.getUserRoleRights(data)
      .subscribe(
        (res) => {
          this.urolerightsColl = res.json().result;
          this.fnSetTable();

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  
  
  //Get 
  fngetUserrole() {        
       var data = {
         'userroleid':this.userroleid,            
       }
       this.appService.getUserrole(data)
       .subscribe(
         (res)=>{                  
           this.userroleColl=res.json().result;
           var editdata =this.userroleColl[0];
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
  }
  
  
  
  
  //Save Btn Click
  fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.userroleform.valid){
           //Check
           var data = {
                 'userroleid': this.userroleid,                   
                 'userrolename':this.userroleform.get('userrolename').value,                 
                 'inactive':this.userroleform.get('inactive').value,     
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
                 'urolerightsColl':this.urolerightsColl,
           }
           this.appService.fnCheckUserrole(data).subscribe(
             (res)=>{
               console.log(res.json())
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.userroleform.get('userroleid').value>0){
                         //Update Department
                         this.fnSetUserrole(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertUserrole(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.userroleform.controls['userrolename'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertUserrole(data){    
   
     this.appService.fnInsertUserrole(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new userrole has been added successfully.Insert Successfully");
  
         this.fnInsertActivityLog('INSERT',0,data.userrolename);
  
         this.fnCloseUserrole();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetUserrole(data){    
  
  this.appService.fnSetUserrole(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The userrole has been updated successfully.");
  
       this.fnInsertActivityLog('EDIT',this.userroleid,data.userrolename);
  
       this.fnCloseUserrole();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });
  
  }
  
  //Close
  fnCloseUserrole(){
  try{
    this.router.navigate(['/Userrole']);
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  private fnEditMode(deptBO){
  
   this.userroleform.setValue({

    userroleid:deptBO.userroleid,
    userrolename:deptBO.userrolename,
        inactive:deptBO.inactive,   
   });
  
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  fnCheckAllRights(event,index){

    if(event){
      this.urolerightsColl[index].uappcreate=true;
    }
    else{
      this.urolerightsColl[index].uappcreate=false;
    }
    if(event){
      this.urolerightsColl[index].uappalter=true;
    }
    else{
      this.urolerightsColl[index].uappalter=false;
    }
    if(event){
      this.urolerightsColl[index].uappdelete=true;
    }
    else{
      this.urolerightsColl[index].uappdelete=false;
    }
    if(event){
      this.urolerightsColl[index].uappview=true;
    }
    else{
      this.urolerightsColl[index].uappview=false;
    }
    if(event){
      this.urolerightsColl[index].uappprint=true;
    }
    else{
      this.urolerightsColl[index].uappprint=false;
    }
    if(event){
      this.urolerightsColl[index].uappexport=true;
    }
    else{
      this.urolerightsColl[index].uappexport=false;
    }
}
  
  fnInsertActivityLog(action,refno,desc){
    try{
      var data={
        logtype:action,
        logat:this.formname,
        refnum:refno,
        logdescr:desc,
        logby:this.loginService.fnGetModUserId(),
      }
    
  
      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{
       
        });
  
    }catch{
  
    }
  }
  
  
  
  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
  }
  
  
  
  
  
  }
  