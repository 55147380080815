import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { LoginComponent } from './components/login/login.component';


import { UomComponent } from './components/uom/uom.component';
import { AdduomComponent } from './components/uom/adduom/adduom.component';

import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';

import { UserroleComponent } from './components/userrole/userrole.component';
import { AdduserroleComponent } from './components/userrole/adduserrole/adduserrole.component';
import { PurchaseComponent } from './componentstrnsaction/purchase/purchase.component';
import { AddpurchaseComponent } from './componentstrnsaction/purchase/addpurchase/addpurchase.component';
import { InstituteComponent } from './components/institute/institute.component';
import { AddinstituteComponent } from './components/institute/addinstitute/addinstitute.component';
import { MohallaComponent } from './components/mohalla/mohalla.component';
import { AddmohallaComponent } from './components/mohalla/addmohalla/addmohalla.component';
import { ProfessionComponent } from './components/profession/profession.component';
import { AddprofessionComponent } from './components/profession/addprofession/addprofession.component';
import { QualificationComponent } from './components/qualification/qualification.component';
import { AddqualificationComponent } from './components/qualification/addqualification/addqualification.component';
import { MemberComponent } from './components/member/member.component';
import { AddmemberComponent } from './components/member/addmember/addmember.component';
import { FamilyComponent } from './components/family/family.component';
import { AddfamilyComponent } from './components/Family/addFamily/addFamily.component';
import { EditfamilyComponent } from './components/family/editfamily/editfamily.component';
import { RelationshipComponent } from './components/relationship/relationship.component';
import { AddrelationshipComponent } from './components/relationship/addrelationship/addrelationship.component';
import { CityComponent } from './components/city/city.component';
import { AddcityComponent } from './components/city/addcity/addcity.component';
import { StreetComponent } from './components/street/street.component';
import { AddstreetComponent } from './components/street/addstreet/addstreet.component';
import { AddcompanyComponent } from './components/company/addcompany/addcompany.component';
import { CompanyComponent } from './components/company/company.component';
import { TempfamilyComponent } from './components/family/tempfamily/tempfamily.component';
import { TaxComponent } from './components/tax/tax.component';
import { ViewtaxComponent } from './components/tax/viewtax/viewtax.component';
import { AddtaxComponent } from './components/tax/addtax/addtax.component';
import { MemberreportComponent } from './components/report/memberreport/memberreport.component';
import { VmjprreportComponent } from './components/report/vmjprreport/vmjprreport.component';
import { TaxpayerreportComponent } from './components/report/taxpayerreport/taxpayerreport.component';
import { BloodgroupreportComponent } from './components/report/bloodgroupreport/bloodgroupreport.component';
import { QualificationreportComponent } from './components/report/qualificationreport/qualificationreport.component';
import { WorkingabroadreportComponent } from './components/report/workingabroadreport/workingabroadreport.component';
import { WorkingoutcityreportComponent } from './components/report/workingoutcityreport/workingoutcityreport.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'Uom', component: UomComponent },
  { path: 'AddUom', component: AdduomComponent }, 
  { path: 'User', component: UserComponent },
  { path: 'AddUser', component: AdduserComponent },  
  { path: 'Userrole', component: UserroleComponent },
  { path: 'AddUserrole', component: AdduserroleComponent },
  { path: 'Purchase', component: PurchaseComponent },
  { path: 'AddPurchase', component: AddpurchaseComponent }, 
  { path: 'Institute', component: InstituteComponent },
  { path: 'AddInstitute', component: AddinstituteComponent }, 
  { path: 'Mohalla', component: MohallaComponent },
  { path: 'AddMohalla', component: AddmohallaComponent },
  { path: 'Profession', component: ProfessionComponent },
  { path: 'AddProfession', component: AddprofessionComponent },
  { path: 'Qualification', component: QualificationComponent },
  { path: 'AddQualification', component: AddqualificationComponent },
  { path: 'Member', component: MemberComponent },
  { path: 'AddMember', component: AddmemberComponent },       
  { path: 'AddMember', component: AddmemberComponent },
  { path: 'Relationship', component: RelationshipComponent },
  { path: 'AddRelationship', component: AddrelationshipComponent },
  { path: 'Family', component: FamilyComponent },  
  { path: 'AddFamily', component: EditfamilyComponent },
  { path: 'City', component: CityComponent },
  { path: 'AddCity', component: AddcityComponent },
  { path: 'Street', component: StreetComponent },
  { path: 'AddStreet', component: AddstreetComponent },
  { path: 'Company', component: CompanyComponent },
  { path: 'AddCompany', component: AddcompanyComponent },
  { path: 'TempFamily', component: TempfamilyComponent },
  { path: 'Tax', component: TaxComponent },  
  { path: 'ViewTax', component: ViewtaxComponent },  
  { path: 'AddTax', component: AddtaxComponent },  

  { path: 'MemberReport', component: MemberreportComponent },  
  { path: 'VMJPRReport', component: VmjprreportComponent },  
  { path: 'TaxPayerReport', component: TaxpayerreportComponent },  
  { path: 'QualificationReport', component: QualificationreportComponent },  
  { path: 'BloodGroupReport', component: BloodgroupreportComponent }, 
  { path: 'WorkingAbroadReport', component: WorkingabroadreportComponent }, 
  { path: 'WorkingOutCityReport', component: WorkingoutcityreportComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
