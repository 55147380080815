
export const AppSettings = {
  //base_url: 'http://13.127.71.165:7200/',
  //base_url: 'http://200.1.50.102:7003/',
  //base_url: 'http://192.168.0.181:7003/',
  //base_url: 'http://200.1.50.108:7003/',
  base_url: 'https://apivmj.renoinfo.in/',


  //Uom
  insertUomUrl: 'uom/insertuom',
  setUomUrl: 'uom/setuom',
  checkUomUrl: 'uom/checkuom',
  getUomUrl: 'uom/getuom',
  deleteUomUrl: 'uom/deleteuom',


  //User
  insertUserUrl: 'user/insertuser',
  setUserUrl: 'user/setuser',
  checkUserUrl: 'user/checkuser',
  getUserUrl: 'user/getuser',
  deleteUserUrl: 'user/deleteuser',
  getLoginUserUrl: 'user/getloginuser',

  //Userrole
  insertUserroleUrl: 'userrole/insertuserrole',
  setUserroleUrl: 'userrole/setuserrole',
  checkUserroleUrl: 'userrole/checkuserrole',
  getUserroleUrl: 'userrole/getuserrole',
  getUserRoleRightsUrl: 'userrole/getuserrolerights',
  getUserRoleRightsUserUrl: 'userrole/getuserrolerightsbyuserid',

  deleteUserroleUrl: 'userrole/deleteuserrole',

  //Purchase
  insertPurchaseUrl: 'purchase/insertpurchase',
  setPurchaseUrl: 'purchase/setpurchase',
  getPurchaseByListviewUrl: 'purchase/getpurchasebylistview',
  getPurchaseByPurchaseidUrl: 'purchase/getpurchasebypurchaseid',
  deletePurchaseUrl: 'purchase/deletepurchase',


  //Institute
  insertInstituteUrl: 'institute/insertinstitute',
  setInstituteUrl: 'institute/setinstitute',
  checkInstituteUrl: 'institute/checkinstitute',
  getInstituteUrl: 'institute/getinstitute',
  deleteInstituteUrl: 'institute/deleteinstitute',


  //Mohalla
  insertMohallaUrl: 'mohalla/insertmohalla',
  setMohallaUrl: 'mohalla/setmohalla',
  checkMohallaUrl: 'mohalla/checkmohalla',
  getMohallaUrl: 'mohalla/getmohalla',
  deleteMohallaUrl: 'mohalla/deletemohalla',


  //Profession
  insertProfessionUrl: 'profession/insertprofession',
  setProfessionUrl: 'profession/setprofession',
  checkProfessionUrl: 'profession/checkprofession',
  getProfessionUrl: 'profession/getprofession',
  deleteProfessionUrl: 'profession/deleteprofession',

  //Qualification
  insertQualificationUrl: 'qualification/insertqualification',
  setQualificationUrl: 'qualification/setqualification',
  checkQualificationUrl: 'qualification/checkqualification',
  getQualificationUrl: 'qualification/getqualification',
  deleteQualificationUrl: 'qualification/deletequalification',

  //Member
  insertMemberUrl: 'member/insertmember',
  setMemberUrl: 'member/setmember',
  setMemberAddressUrl: 'member/setmemberaddress',
  
  checkMemberUrl: 'member/checkmember',
  getMemberUrl: 'member/getmember',
  deleteMemberUrl: 'member/deletemember',
  getdashboardadminUrl: 'member/getdashboardadmin',

  //Family
  insertFamilyUrl: 'family/insertfamily',
  setFamilyUrl: 'family/setfamily',
  checkFamilyUrl: 'family/checkfamily',
  getFamilyListviewUrl: 'family/getfamilylistview',
  getFamilyUrl: 'family/getfamily',
  getFamilyByMemberidUrl: 'family/getfamilybymemberid',
  getFamilyByHeaderUrl: 'family/getfamilybyheader',
  deleteFamilyUrl: 'family/deletefamily',

  //Relationship
  insertRelationshipUrl: 'relationship/insertrelationship',
  setRelationshipUrl: 'relationship/setrelationship',
  checkRelationshipUrl: 'relationship/checkrelationship',
  getRelationshipUrl: 'relationship/getrelationship',
  deleteRelationshipUrl: 'relationship/deleterelationship',

  //City
  insertCityUrl: 'city/insertcity',
  setCityUrl: 'city/setcity',
  checkCityUrl: 'city/checkcity',
  getCityUrl: 'city/getcity',
  deleteCityUrl: 'city/deletecity',

  //Street
  insertStreetUrl: 'street/insertstreet',
  setStreetUrl: 'street/setstreet',
  checkStreetUrl: 'street/checkstreet',
  getStreetUrl: 'street/getstreet',
  deleteStreetUrl: 'street/deletestreet',


  //Tax
  insertTaxUrl: 'tax/inserttax',
  setTaxUrl: 'tax/settax',
  checkTaxUrl: 'tax/checktax',
  getTaxUrl: 'tax/gettax',
  getTaxListViewUrl: 'tax/gettaxlistview',
  getTaxDetailsByFamilyIdUrl: 'tax/gettaxdetailsbyfamilyid',
  deleteTaxUrl: 'tax/deletetax',

  //Company
  insertCompanyUrl: 'company/insertcompany',
  setCompanyUrl: 'company/setcompany',
  checkCompanyUrl: 'company/checkcompany',
  getCompanyUrl: 'company/getcompany',
  deleteCompanyUrl: 'company/deletecompany',

  //Activitylog
  insertActivitylogUrl: 'common/insertactivitylog',


  //Report
  getReportAllUrl: 'member/getreportall',

}


