import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reportgrid',
  templateUrl: './reportgrid.component.html',
  styleUrls: ['./reportgrid.component.css']
})
export class ReportgridComponent implements OnInit {

  pageno: any = 500;

  pages = [25, 50, 100, 500];
  
  p: number = 1;

  

  filterMetadata = { count: 0 };

  @Input() formname: any = "";

  @Input() contentcoll: any = [];
  @Input() searchtxt: any = "";
  @Input() gridOptions = {};
  @Input() isedit:any=false;
  @Input() isdelete:any=false;

  @Output() editChanged = new EventEmitter<any>();
  @Output() deleteChanged = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  fnEditBtnClick(pBO,type) {
    this.editChanged.emit(pBO)
  }

  fnDeleteBtnClick(pBO) {
    this.deleteChanged.emit(pBO)
  }

}
