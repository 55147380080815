import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { AddprofessionComponent } from '../../profession/addprofession/addprofession.component';
import { AddqualificationComponent } from '../../qualification/addqualification/addqualification.component';
import { AddinstituteComponent } from '../../institute/addinstitute/addinstitute.component';
import { AddmohallaComponent } from '../../mohalla/addmohalla/addmohalla.component';
import { AddstreetComponent } from '../../street/addstreet/addstreet.component';
import { AddcityComponent } from '../../city/addcity/addcity.component';


@Component({
  selector: 'app-editaddress',
  templateUrl: './editaddress.component.html',
  styleUrls: ['./editaddress.component.css']
})
export class EditaddressComponent implements OnInit {

  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  isstreetaddmode: boolean = false;
  iscityaddmode: boolean = false;
  ismohallaaddmode: boolean = false;
  isqualificationaddmode: boolean = false;
  isprofessionaddmode: boolean = false;

  isdisablerelationship: boolean = false;
  isdisablegender: boolean = false;
  isdisablemaritalstatus: boolean = false;
  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  memberColl: any = [];
  streetColl: any = [];
  cityColl: any = [];

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Member";


  memberform = new FormGroup({
    memberid: new FormControl(null),   
    doorno: new FormControl(''),
    streetid: new FormControl(null),
    cityid: new FormControl(null),
    isownhouse: new FormControl(false),
    doornopresent: new FormControl(''),
    presentstreetid: new FormControl(null),
    presentcityid: new FormControl(null),
    isownhousepresent: new FormControl(false), 
    isdifferentaddress: new FormControl(false),
    isusepermanataddress: new FormControl(false),   
    inactive: new FormControl(false),
  });

  relationshipname: any = '';
  qualificationyrs: any = 0;


  @Input() memberid: number = 0;
  @Input() membername: any = '';
  @Input() familynickname: any = "";
  @Input() parentmemberid: number = 0;
  @Input() parentmemberBO: any = {};
  @Input() entrymode: any = "";
  @Output() emitData = new EventEmitter();

  @Input() iswife: boolean = false;

  
  @Input() fathername: any = "";
  @Input() doorno: any = "";
  @Input() streetid: any =null;
  @Input() cityid: any = null;

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  age: number = 0;

  constructor(private toastr: ToastrService, private appService: DhukanDataService, private productFindService: NgbModal, private route: ActivatedRoute, private datePipe: DatePipe,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    // this.route.queryParams.subscribe(params => {
    //   if (params.id !== undefined) {
    //     this.memberid = params.id
    //   }
    // });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    ////this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {
      this.isdisablerelationship = false;

      this.memberform.reset();
      this.fnInitializeMemberFormGroup();

      if (this.memberid > 0) {
        this.fngetMember();
      }
   
      this.fngetStreet();
      this.fngetCity();
      this.fnSetAddressByParent();



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.memberid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    this.isstreetaddmode = this.loginService.fnCheckUserRoleAction("Street", 'CREATE');
    this.iscityaddmode = this.loginService.fnCheckUserRoleAction("City", 'CREATE');
    this.ismohallaaddmode = this.loginService.fnCheckUserRoleAction("Mohalla", 'CREATE');
    this.isqualificationaddmode = this.loginService.fnCheckUserRoleAction("Qualification", 'CREATE');
    this.isprofessionaddmode = this.loginService.fnCheckUserRoleAction("Profession", 'CREATE');

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeMemberFormGroup() {

    this.memberform.setValue({

      memberid: 0,
      
     doorno: '',
      streetid: null,
      cityid: null,
      isownhouse: false,
      doornopresent: '',
      presentstreetid: null,
      presentcityid: null,
      isownhousepresent: false,     
      isdifferentaddress: false,
      isusepermanataddress: false,
      inactive: false,
    })



    if (this.parentmemberid === 0) {
  
      // this.memberform.controls['fathername'].setValidators(Validators.required);   
     // this.memberform.controls['doorno'].setValidators(Validators.required);   
      //this.memberform.controls['streetid'].setValidators(Validators.required);   
      //this.memberform.controls['cityid'].setValidators(Validators.required);   
    }

  }


  fngetStreet() {
    var data = {
      'streetid': 0,
    }
    this.appService.getStreet(data)
      .subscribe(
        (res) => {
          this.streetColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetCity() {
    var data = {
      'cityid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetMember() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getMember(data)
      .subscribe(
        (res) => {
          this.memberColl = res.json().result;
          var editdata = this.memberColl[0];
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnSetAddressByParent() {

    if (this.parentmemberid > 0 && this.memberid === 0) {

      this.memberform.patchValue({
        'doorno': this.parentmemberBO.doorno,
        'streetid': this.parentmemberBO.streetid,
        'cityid': this.parentmemberBO.cityid,
        'isownhouse': this.parentmemberBO.isownhouse,
        'isusepermanataddress': this.parentmemberBO.isusepermanataddress,
        'isownhousepresent': this.parentmemberBO.isownhousepresent,

      })

    }

  }



  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.memberform.valid) {
        if (this.entrymode === 'TEMP') {
          this.memberform.patchValue({'istemp':true})
        }

        //Check
        var data = {

          'memberid': this.memberid,          
          'doorno': this.memberform.get('doorno').value,
          'streetid': this.memberform.get('streetid').value,
          'cityid': this.memberform.get('cityid').value,
          'isownhouse': this.memberform.get('isownhouse').value,
          'doornopresent': this.memberform.get('doornopresent').value,
          'presentstreetid': this.memberform.get('presentstreetid').value,
          'presentcityid': this.memberform.get('presentcityid').value,
          'isownhousepresent': this.memberform.get('isownhousepresent').value,    
          'isdifferentaddress': this.memberform.get('isdifferentaddress').value,
          'isusepermanataddress': this.memberform.get('isusepermanataddress').value,          
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        if (this.memberform.get('memberid').value > 0) {
          this.fnSetMemberAddess(data);
        }          
      }
    } catch (ex) {
      console.log(ex)
      this.fnShowErrorMsg(ex);
    }
  }



  //Set Member Address
  fnSetMemberAddess(data) {

    this.appService.fnSetMemberAddress(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;



          this.toastr.success('', "The address has been added successfully.");
          this.emitData.emit(0);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnAddStreet(type) {

    const modalRef = this.productFindService.open(AddstreetComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetStreet();

      if (receivedEntry > 0) {
        if (type === "STREET") {
          this.memberform.patchValue({ streetid: receivedEntry })
        }
        else if (type === "PRESENTSTREET") {
          this.memberform.patchValue({ presentstreetid: receivedEntry })
        }

      }
    });

  }

  private fnAddCity(type) {

    const modalRef = this.productFindService.open(AddcityComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetCity();

      if (receivedEntry > 0) {
        if (type === "CITY") {
          this.memberform.patchValue({ cityid: receivedEntry })
        }
        else if (type === "PRESENTCITY") {
          this.memberform.patchValue({ presentcityid: receivedEntry })
        }
        else if (type === "BIRTHCITY") {
          this.memberform.patchValue({ placeofbirthcityid: receivedEntry })
        }
        else if (type === "PROFESSIONALPLACECITY") {
          this.memberform.patchValue({ professionplacecityid: receivedEntry })
        }
      }
    });

  }



  private fnEditMode(deptBO) {
    this.isdisablerelationship = false;
    deptBO.dateofbirth = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.dateofbirth));

    this.memberform.setValue({

      memberid: deptBO.memberid,   
      doorno: deptBO.doorno,
      streetid: deptBO.streetid,
      cityid: deptBO.cityid,
      isownhouse: deptBO.isownhouse,
      doornopresent: deptBO.doornopresent,
      presentstreetid: deptBO.presentstreetid,
      presentcityid: deptBO.presentcityid,
      isownhousepresent: deptBO.isownhousepresent,   
      isdifferentaddress: deptBO.isdifferentaddress,
      isusepermanataddress: deptBO.isusepermanataddress,
      inactive: deptBO.inactive,

    });

    this.qualificationyrs = deptBO.qualificationyrs

    this.picurl = deptBO.photopicurl

    if (deptBO.isfamilyhead) {
      this.isdisablerelationship = true;
      this.relationshipname = deptBO.relationshipname
    }


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }
  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }



}
