import { EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AddmemberComponent } from '../../member/addmember/addmember.component';
import { AddmembershortComponent } from '../../member/addmembershort/addmembershort.component';
import { EditaddressComponent } from '../../member/editaddress/editaddress.component';
import { PrintfamilyComponent } from '../printfamily/printfamily.component';

@Component({
  selector: 'app-editfamilypop',
  templateUrl: './editfamilypop.component.html',
  styleUrls: ['./editfamilypop.component.css']
})
export class EditfamilypopComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;


  isaddmembermode: boolean = false;
  iseditmembermode: boolean = false;
  isdeletemembermode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  familyColl: any = [];
  relationshipColl: any = [];
  familychildColl: any = [];
  familychild2Coll: any = [];
  familychild4Coll: any = [];

  memberBO: any = {};

  headerBO: any = {};

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Family";

  familyform = new FormGroup({
    familyid: new FormControl(null),
    familyno: new FormControl('', Validators.required),
    familynickname: new FormControl(''),
    memberid: new FormControl(null, Validators.required),
    parentmemberid: new FormControl(null),
    relationshipid: new FormControl(null, Validators.required),
    isfamilyhead: new FormControl(false),
    orderno: new FormControl(null),
    inactive: new FormControl(false),
  });


  @Input() familyid: number = 0;
  @Input() familyname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, public router: Router, private productFindService: NgbModal, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal,private confirmationDialogService: ConfirmationDialogService) {
  
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      this.isaddmode = true;
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.familyform.reset();
      this.fnInitializeFamilyFormGroup();

      if (this.familyid > 0) {
        this.fngetFamily();
      }

      this.fngetRelationShip();



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fngetRelationShip() {
    var data = {
      'relationshipid': 0,
    }
    this.appService.getRelationship(data)
      .subscribe(
        (res) => {
          this.relationshipColl = res.json().result;

          if (this.familyid === 0) {
            for (let abo of this.relationshipColl) {
              if (abo.isfamilyhead) {
                this.familyform.patchValue({ 'relationshipid': abo.relationshipid })
              }
            }
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  

  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.familyid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    
   this.isaddmembermode = this.loginService.fnCheckUserRoleAction("Member", 'CREATE')
   this.iseditmembermode = this.loginService.fnCheckUserRoleAction("Member", 'ALTER')
   this.isdeletemembermode = this.loginService.fnCheckUserRoleAction("Member", 'DELETE')

    
    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeFamilyFormGroup() {
    this.familyform.setValue({
      familyid: 0,
      familyno: 'NEW',
      familynickname: '',
      memberid: null,
      parentmemberid: null,
      relationshipid: null,
      isfamilyhead: true,
      orderno: null,
      inactive: false
    })
  }





  //Get 
  fngetFamily() {
    var data = {
      'familyid': this.familyid,
    }
    this.appService.getFamily(data)
      .subscribe(
        (res) => {

          this.familyColl = res.json().result;
          var editdata = this.familyColl[0];
          this.fnEditMode(editdata);

          this.memberBO = editdata;

          this.fngetFamilyByMemberid()

          this.fngetFamilyByHeader(editdata.memberid)
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetFamilyByMemberid() {
    var data = {
      'memberid': this.familyform.get('memberid').value,
    }
    this.appService.getFamilyByMemberid(data)
      .subscribe(
        (res) => {

          this.familychildColl = res.json().result[0];
          this.familychild2Coll = res.json().result[1];
          this.familychild4Coll= res.json().result[3];
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  
  fngetFamilyByHeader(memberid) {
    var data = {
      'memberid':memberid,
    }
    this.appService.getFamilyByHeader(data)
      .subscribe(
        (res) => {

          var dataColl = res.json().result;

          if (dataColl.length > 0) {
            this.headerBO = dataColl[0];
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.familyform.valid) {
        //Check
        var data = {
          'familyid': this.familyid,
          familyno: this.familyform.get('familyno').value,
          familynickname: this.familyform.get('familynickname').value,
          memberid: this.familyform.get('memberid').value,
          parentmemberid: this.familyform.get('parentmemberid').value,
          relationshipid: this.familyform.get('relationshipid').value,
          isfamilyhead: this.familyform.get('isfamilyhead').value,
          orderno: this.familyform.get('orderno').value,
          'inactive': this.familyform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckFamily(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.familyform.get('familyid').value > 0) {
                //Update Department
                this.fnSetFamily(data);
              }
              else {
                //Insert Department
                this.fnInsertFamily(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.familyform.controls['memberid'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertFamily(data) {

    this.appService.fnInsertFamily(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var familyid = 0;
          if (result.length > 0) {
            familyid = result[0].familyid
          }

          this.fnInsertActivityLog('INSERT', 0, this.familyname);

          this.toastr.success('', "The new family has been added successfully.Insert Successfully");
          // this.emitData.emit(familyid);
          // this.activeModal.close(true);
          this.fnReloadFamily(familyid);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetFamily(data) {

    this.appService.fnSetFamily(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Family has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.familyid, data.familyname);

          this.fnCloseFamily();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.familyform.setValue({
      familyid: deptBO.familyid,
      familyno: deptBO.familyno,
      familynickname: deptBO.familynickname,
      memberid: deptBO.memberid,
      parentmemberid: deptBO.parentmemberid,
      relationshipid: deptBO.relationshipid,
      isfamilyhead: deptBO.isfamilyhead,
      orderno: deptBO.orderno,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  //Close
  fnCloseFamily() {
    try {
      this.emitData.emit("DONE");
      this.activeModal.close(true);;
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnReloadFamily(id) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': id,
        }
      }

      this.router.navigate(['/AddFamily'], navigationExtras).then(() => {
        window.location.reload()
      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnAddMember() {
    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static' });
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.parentmemberid = 0;
    modalRef.componentInstance.familynickname = this.familyform.get('familynickname').value;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      if (this.familyid === 0 && receivedEntry > 0) {

        this.fnReloadFamily(receivedEntry);

        // this.familyform.patchValue({ 'memberid': receivedEntry })

        // this.fnSaveBtnClick();
      }


    });
  }

  
  fnAddWifeMember() {

    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static',keyboard: false, });   
    modalRef.componentInstance.parentmemberid = this.memberBO.memberid;
    modalRef.componentInstance.familynickname = this.memberBO.familynickname;
    modalRef.componentInstance.parentmemberBO = this.memberBO;
    modalRef.componentInstance.iswife =true;
    
    modalRef.componentInstance.doorno = this.memberBO.doorno;
    modalRef.componentInstance.streetid = this.memberBO.streetid;
    modalRef.componentInstance.cityid = this.memberBO.cityid;

    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetFamily();
    });
  }

  fnEditMember(memberid) {

    if (!this.iseditmembermode) {
      return
    }

    const modalRef = this.productFindService.open(AddmemberComponent, { size: <any>'xl', backdrop: 'static',keyboard: false });
    modalRef.componentInstance.memberid = memberid;     
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetFamily();
    });
  }


  fnAddChildMember() {
    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static' ,keyboard: false});    
    modalRef.componentInstance.parentmemberid = this.memberBO.memberid;
    modalRef.componentInstance.familynickname = this.memberBO.familynickname;
    modalRef.componentInstance.parentmemberBO = this.memberBO;

    modalRef.componentInstance.doorno = this.memberBO.doorno;
    modalRef.componentInstance.streetid = this.memberBO.streetid;
    modalRef.componentInstance.cityid = this.memberBO.cityid;
    
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetFamily();
    });
  }

  fnEditFamilyPop(familyid) {
    if (!this.iseditmembermode) {
      return
    }
    const modalRef = this.productFindService.open(EditfamilypopComponent, { size: <any>'xs', backdrop: 'static',keyboard: false });    
    modalRef.componentInstance.familyid = familyid;
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
     
        this.fngetFamily();
      
    });
  }


  fnEditMemberAddress(memberid) {
    if (!this.iseditmembermode) {
      return
    }
    
    const modalRef = this.productFindService.open(EditaddressComponent, { size: <any>'l', backdrop: 'static',keyboard: false, });
    modalRef.componentInstance.memberid = memberid; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetFamily();
    });
  }

  
  public fnDeleteConfirm(pBO) {
    try {

      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Family?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteFamily(pBO)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteFamily(pBO) {
    var deptid = pBO.familyid
    var data = {
      'familyid': deptid,
    }
    this.appService.fnDeleteFamily(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            var result = res.json().result;

            console.log(result)
            var checkresult = '';
            if (result.length > 0) {
              checkresult=result[0].checkresult
            }
            
            if (checkresult === 'DONE') {
              this.toastr.success('', " The " + pBO.membername + " family has been deleted successfully.");

               this.fngetFamily();
            }
            else {
              this.toastr.error('', checkresult);
            }

          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnPrintSlip() {

    const modalRef = this.productFindService.open(PrintfamilyComponent, { size: <any>'m', backdrop: 'static' });    
    modalRef.componentInstance.memberBO = this.memberBO;
    modalRef.componentInstance.familychildColl=this.familychild4Coll
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
      
    });
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }




  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }



}
