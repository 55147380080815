import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddqualificationComponent } from './addqualification/addqualification.component';
@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit,AfterViewInit, OnDestroy {
 

  qualificationColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Qualification";

  searchtxt: any = "";
  columnDefs = [
   
  
    { headerName: 'Qualification', field: 'qualificationname' },
 

  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };
  

  constructor( private appService: DhukanDataService,public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }

   //User Check
   fnServiceChanges(){
    this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }


  ngOnInit() {
    try{
 

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetQualification();     
  }

  fnCheckPrivilage() {
    
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  


  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
  }

 

  
  fngetQualification() {  
         
    if(this.showProgress){
      return;
    }
     
    this.fnShowProgress();
      var data = {
            'qualificationid': 0,            
      }
      this.appService.getQualification(data)
          .subscribe(
            (res)=>{             
              
              this.fnHideProgress();
              if(res.json().status==200){                          
                this.qualificationColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddQualification() {
    try {
      const modalRef = this.productFindService.open(AddqualificationComponent, { size: <any>'m', backdrop: 'static' });

     
      modalRef.componentInstance.ngOnInit();

      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        this.fngetQualification();

      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditQualification(pBO) {
    try {
      var deptid = pBO.qualificationid
      if (!this.isedit) {
        return;
      }
      const modalRef = this.productFindService.open(AddqualificationComponent, { size: <any>'m', backdrop: 'static' });
      modalRef.componentInstance.qualificationid = deptid;
    
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

        this.fngetQualification();

      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
 
  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.qualificationid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Qualification?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteQualification(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteQualification(deptid){
       var data = {
         'qualificationid': deptid,            
       }
      this.appService.fnDeleteQualification(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
             this.toastr.success('', " The Qualification has been deleted successfully.");                 

             this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
             
              this.fngetQualification();
            }else{
              this.toastr.error('',res.json().err_field);   
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

 

  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'productgrouplist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
   
  }


     
  private fnSetDataTable() {
  
  }

      
  fnPrintData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
                         
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  

fnGetDescription(id){
  const index = this.qualificationColl.findIndex(sku => sku.qualificationid === id  ); 

  if(index){
    return this.qualificationColl[index].productgroup;
  }
  else{
    return "";
  }

}
public fnSearchDatatable(value){
    
  // this.dtElement.dtInstance.then((dtInstance:DataTables.Api)=>{
  //   dtInstance.search(value).draw();
  // })

}
fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}


  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
