import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit, AfterViewInit, OnDestroy {


  taxColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Family";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'VMJPR No', field: 'familyno' },
    { headerName: 'Name', field: 'membername', colcls:'w200' },
    { headerName: 'Family', field: 'familynicknamelist', colcls:'w200' },
    { headerName: 'Taxer', field: 'taxercount' },
    { headerName: 'Balance', field: 'balance', type: 'decimal' },
    { headerName: 'Advance', field: 'advance',type:'decimal' },
    { headerName: 'Street', field: 'streetname' },
    { headerName: 'City', field: 'cityname' },    
    { headerName: 'Opening', field: 'openingstatus' },
 
    
  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };


  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetFamily();
  }

  fnCheckPrivilage() {

    this.isadd = false;
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = false
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }




  fngetFamily() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'taxid': 0,
    }
    this.appService.getTaxListView(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.taxColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnEditFamily(pBO) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': pBO.familyid,
        }
      }

      this.router.navigate(['/ViewTax'], navigationExtras);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.familyid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Family?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteFamily(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteFamily(deptid) {
    var data = {
      'familyid': deptid,
    }
    this.appService.fnDeleteFamily(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            var result = res.json().result;
            var checkresult = '';
            if (result.length > 0) {
              checkresult = result[0].checkresult
            }
            if (checkresult === 'DONE') {
              this.toastr.success('', " The family has been deleted successfully.");
              this.fngetFamily();
            }
            else {
              this.toastr.error('', checkresult);
            }


          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fnActionExportClick() {


  }



  private fnSetDataTable() {

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.taxColl.findIndex(sku => sku.familyid === id);

    if (index) {
      return this.taxColl[index].familyno;
    }
    else {
      return "";
    }

  }
  public fnSearchDatatable(value) {

    // this.dtElement.dtInstance.then((dtInstance:DataTables.Api)=>{
    //   dtInstance.search(value).draw();
    // })

  }
  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
