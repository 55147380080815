import { Component, OnInit } from '@angular/core';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  isinstitude:boolean=false;
  iscompany:boolean=false;
  ismohalla:boolean=false;
  isprofession:boolean=false;
  isqualification:boolean=false;
  isrelationship: boolean = false;
  iscity: boolean = false;
  isstreet:boolean=false;
  ismember: boolean = false;
  isfamily: boolean = false;
  
  isuser: boolean = false;
  isuserrole:boolean=false;


  issettingsmenu:boolean=false;
  ismastermenu:boolean=false;
  


  

  constructor(private loginService: LoginDataService,public router: Router) {  
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
      this.userView = value                 
      if(this.userView.userBO){                
        this.userBO= this.userView.userBO  
       
      }
    
      this.fnCheckMenu();
      
    }); 
  }

  ngOnInit() {
    this.loginService.fnGetUserRoleList();
   
  }


  fnCheckMenu() {

    if (this.loginService.fnCheckUserRoleAll('Institute')) {
      this.isinstitude = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Company')) {
      this.iscompany = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Mohalla')) {
      this.ismohalla = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Profession')) {
      this.isprofession = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Qualification')) {
      this.isqualification = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Relationship')) {
      this.isrelationship = true;
    }
    if (this.loginService.fnCheckUserRoleAll('City')) {
      this.iscity = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Street')) {
      this.isstreet = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Member')) {
      this.ismember = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Family')) {
      this.isfamily = true;
    }
    if (this.loginService.fnCheckUserRoleAll('User')) {
      this.isuser = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Userrole')) {
      this.isuserrole = true;
    }
    

  

    if (this.isinstitude || this.iscompany || this.ismohalla || this.isprofession || this.isqualification ||
      this.isrelationship || this.iscity || this.isstreet) {
      this.ismastermenu = true;
    }

    if (this.isuser || this.isuserrole) {
      this.issettingsmenu = true;
    }

  }


  fnReportMenuClick(type){
    try{      
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'type': type,
        }
        }

      this.router.navigate(['/Report'], navigationExtras);
    }catch(ex){
    
    }
  }

  fnLogout(){
    sessionStorage.removeItem("rmstoken");
    this.router.navigate(['/Login']);
  }

 
  

 

  fnPartyMenuClick(type){
    try{      
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'ptype': type,
        }
        }

      this.router.navigate(['/Party'], navigationExtras);
    }catch(ex){
    
    }
  }
  

}
