import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addinstitute',
  templateUrl: './addinstitute.component.html',
  styleUrls: ['./addinstitute.component.css']
})
export class AddinstituteComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  instituteColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Institute";

  instituteform = new FormGroup({
    instituteid: new FormControl(null),
    institutename: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });


  @Input() instituteid: number = 0;
  @Input() institutename: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.instituteid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    ////this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.instituteform.reset();
      this.fnInitializeInstituteFormGroup();

      if (this.instituteid > 0) {
        this.fngetInstitute();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.instituteid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeInstituteFormGroup() {
    this.instituteform.setValue({
      instituteid: 0,
      institutename: '',
      inactive: false,
    })
  }





  //Get 
  fngetInstitute() {
    var data = {
      'instituteid': this.instituteid,
    }
    this.appService.getInstitute(data)
      .subscribe(
        (res) => {
          this.instituteColl = res.json().result;
          var editdata = this.instituteColl[0];
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.instituteform.valid) {
        //Check
        var data = {
          'instituteid': this.instituteid,
          'institutename': this.instituteform.get('institutename').value,
          'inactive': this.instituteform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckInstitute(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.instituteform.get('instituteid').value > 0) {
                //Update Department
                this.fnSetInstitute(data);
              }
              else {
                //Insert Department
                this.fnInsertInstitute(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.instituteform.controls['institutename'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertInstitute(data) {

    this.appService.fnInsertInstitute(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var instituteid = 0;
          if (result.length > 0) {
            instituteid = result[0].instituteid
          }

          this.fnInsertActivityLog('INSERT', 0, instituteid);

          this.toastr.success('', "The new institute has been added successfully.Insert Successfully");
          this.emitData.emit(instituteid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetInstitute(data) {

    this.appService.fnSetInstitute(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Institute has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.instituteid, data.institutename);

          this.emitData.emit(this.instituteid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.instituteform.setValue({
      instituteid: deptBO.instituteid,
      institutename: deptBO.institutename,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{

        });

    } catch {

    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
