import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addcity',
  templateUrl: './addcity.component.html',
  styleUrls: ['./addcity.component.css']
})
export class AddcityComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  cityColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "City";

  cityform = new FormGroup({
    cityid: new FormControl(null),
    cityname: new FormControl('', Validators.required),
    inactive: new FormControl(false),
  });


  @Input() cityid: number = 0;
  @Input() cityname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.cityid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
   // this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.cityform.reset();
      this.fnInitializeCityFormGroup();

      if (this.cityid > 0) {
        this.fngetCity();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.cityid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeCityFormGroup() {
    this.cityform.setValue({
      cityid: 0,
      cityname: '',
      inactive: false,
    })
  }





  //Get 
  fngetCity() {
    var data = {
      'cityid': this.cityid,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;
          var editdata = this.cityColl[0];
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.cityform.valid) {
        //Check
        var data = {
          'cityid': this.cityid,
          'cityname': this.cityform.get('cityname').value,
          'inactive': this.cityform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckCity(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.cityform.get('cityid').value > 0) {
                //Update Department
                this.fnSetCity(data);
              }
              else {
                //Insert Department
                this.fnInsertCity(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.cityform.controls['cityname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertCity(data) {

    this.appService.fnInsertCity(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var cityid = 0;
          if (result.length > 0) {
            cityid = result[0].cityid
          }

          this.fnInsertActivityLog('INSERT', 0, cityid);

          this.toastr.success('', "The new City has been added successfully.Insert Successfully");
          this.emitData.emit(cityid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetCity(data) {

    this.appService.fnSetCity(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The City has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.cityid, data.cityname);

          this.emitData.emit(this.cityid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.cityform.setValue({
      cityid: deptBO.cityid,
      cityname: deptBO.cityname,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
  
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }

      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{

        });

    } catch {

    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
