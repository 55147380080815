import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddcompanyComponent } from './addcompany/addcompany.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit,AfterViewInit, OnDestroy {
 

  companyColl:any=[];
 
   isadd:boolean=false;
   isedit:boolean=false;
   isdelete:boolean=false;
   isview:boolean=false;
   isprint:boolean=false;
    isexport:boolean=false; 
   
   isloading:boolean=false;
   isprivilage:boolean=false;  
 showProgress:boolean=false;
   formname:String="Company";
 
   
   searchtxt: any = "";
   columnDefs = [
     { headerName: 'Company', field: 'companyname' },
   ]
 
   gridOptions = {
     enableactioncol: true,
     columnDefs: this.columnDefs,
   };
 
  
 
   constructor( private appService: DhukanDataService,public router: Router,
     private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService,private loginService:LoginDataService) { this.fnServiceChanges(); }
 
    //User Check
    fnServiceChanges(){
     this.loginService.serviceloginChange.subscribe((value) => {
           if(this.loginService.fnCheckUser()){                
               this.fnCheckPrivilage();
               if(this.isview){
                 this.isloading=true;
                 this.fnOnInitForm();
               }
               else{
                 this.fnUserFailedForm();
               }    
           }
           else{
             this.fnUserFailedForm();
           }
     });
   }
 
 
   ngOnInit() {
     try{
   
     
 
       if(this.loginService.fnCheckUser()){         
         this.fnCheckPrivilage();
         if(this.isview){
           this.isloading=true;
           this.fnOnInitForm();
         }
         else{
           this.fnUserFailedForm();
         }          
       }
       else{
         this.loginService.fnGetUserRoleList();
       }
 
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
   }
 
   fnOnInitForm(){
     this.fngetCompany();     
   }
 
   fnCheckPrivilage() {
     
     this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
     this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
     this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
     this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
     this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
     this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
   }
 
   fnUserFailedForm(){
     this.isprivilage=true;
   }
   
 
 
   ngAfterViewInit(): void {
  
   }
 
   ngOnDestroy(): void {
 
   }
 
  
   
   fngetCompany() {  
          
     if(this.showProgress){
       return;
     }
      
     this.fnShowProgress();
       var data = {
             'companyid': 0,            
       }
       this.appService.getCompany(data)
           .subscribe(
             (res)=>{             
               
               this.fnHideProgress();
               if(res.json().status==200){                          
                 this.companyColl=res.json().result;
                 this.fnSetDataTable();
               }
               else if(res.json().status==400){                          
                 this.toastr.error('Error', res.json().err_field);
               }
              
              
           },
             (err)=>{          
               this.fnHideProgress();
               this.toastr.error('Error', err);
             })
   }
 
   fnAddCompany(){
     try{
       this.router.navigate(['/AddCompany']);
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }    
   }
 
   fnEditCompany(pBO) {
     try {
       var deptid = pBO.companyid
         let navigationExtras: NavigationExtras = {
         queryParams: {
           'id': deptid,
         }
         }
   
       this.router.navigate(['/AddCompany'], navigationExtras);
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
   }
 
   public fnDeleteConfirm(pBO) {
     try {
       var deptid = pBO.companyid
       this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Company?')
       .then((confirmed) => 
             {
               if(confirmed){
                 this.fnDeleteCompany(deptid)
               }          
           }        
       );     
       //.catch(() => );
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
   }
 
   private fnDeleteCompany(deptid){
        var data = {
          'companyid': deptid,            
        }
       this.appService.fnDeleteCompany(data)
         .subscribe(
           (res)=>{ 
             if(res.json().status===200){
              this.toastr.success('', " The Company has been deleted successfully.");                 
 
              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
              
               this.fngetCompany();
             }else{
               this.toastr.error('',res.json().err_field);   
             }
           },
           (err)=>{          
             this.toastr.error('Error', err);
           })
   }
 
   
 
   fnActionExportClick() {
     const params = {
       columnGroups: true,
       allColumns: true,
       fileName: 'companylist',
       //columnSeparator: document.querySelector("#columnSeparator").value
     };
    // this.gridApi.exportDataAsCsv(params);
   }
 
 
      
   private fnSetDataTable() {
     // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
 
     //   dtInstance.destroy();
     //   this.dtTrigger.next();
 
     //   // setTimeout(() => {
     //   //   this.istableloading = true;
     //   //   this.dtTrigger.next();
     //   // },100);
 
     // });
   }
 
       
   fnPrintData(){
     try{
     
                          
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }    
   }
 
   fnExportData(){
     try{
     
                          
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }    
   }
   
 
 fnGetDescription(id){
   const index = this.companyColl.findIndex(sku => sku.companyid === id  ); 
 
   if(index){
     return this.companyColl[index].Companyid;
   }
   else{
     return "";
   }
 
 }
 public fnSearchDatatable(value){
     
   // this.dtElement.dtInstance.then((dtInstance:DataTables.Api)=>{
   //   dtInstance.search(value).draw();
   // })
 
 }
 fnInsertActivityLog(action,refno,desc){
 try{
   var data={
     logtype:action,
     logat:this.formname,
     refnum:refno,
     logdescr:desc,
     logby:this.loginService.fnGetModUserId(),
   }
 
 
   this.appService.fnInsertActivity(data).subscribe(
     (res)=>{
     },
     (err)=>{
    
     });
 
 }catch{
 
 }
 }
 
 
   
 private fnShowProgress(){
   this.showProgress=true;
 }
 private fnHideProgress(){
   this.showProgress=false;
 }
 
 
   private  fnShowErrorMsg(ex){
     this.toastr.warning('Internal Error', ex);       
   }
 
 
 
 
 }
 