import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-printfamily',
  templateUrl: './printfamily.component.html',
  styleUrls: ['./printfamily.component.css']
})
export class PrintfamilyComponent implements OnInit {

  @Input() memberBO: any = {}
  @Input() familychildColl: any = []
  
  currdate = new Date();


  constructor(private activeModal: NgbActiveModal,) { }

  ngOnInit() {
  }


  public decline() {
    this.activeModal.close(false);
  }

  fnPrintSlip() {


    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();

    // let printContents, popupWin;

    // printContents = document.getElementById('print-section').innerHTML;

    // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');    
    // //popupWin.document.write(`<body onload="window.print();window.close()">${printContents}</body>`);
    // popupWin.document.write('<html><head> </head><body onload="window.print()">' + printContents + '</html>');

    // popupWin.document.close();
  }

}
