import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addqualification',
  templateUrl: './addqualification.component.html',
  styleUrls: ['./addqualification.component.css']
})
export class AddqualificationComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  qualificationColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Qualification";

  qualificationform = new FormGroup({
    qualificationid: new FormControl(null),
    qualificationname: new FormControl('', Validators.required),
    qualificationyrs: new FormControl(null),
    inactive: new FormControl(false),
  });


  @Input() qualificationid: number = 0;
  @Input() qualificationname: any = '';
  @Input() qualificationyrs: number = null;
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.qualificationid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.qualificationform.reset();
      this.fnInitializeQualificationFormGroup();

      if (this.qualificationid > 0) {
        this.fngetQualification();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.qualificationid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeQualificationFormGroup() {
    this.qualificationform.setValue({
      qualificationid: 0,
      qualificationname: '',
      qualificationyrs: null,
      inactive: false,
    })
  }





  //Get 
  fngetQualification() {
    var data = {
      'qualificationid': this.qualificationid,
    }
    this.appService.getQualification(data)
      .subscribe(
        (res) => {
          this.qualificationColl = res.json().result;
          var editdata = this.qualificationColl[0];
         
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.qualificationform.valid) {
        //Check
        var data = {
          'qualificationid': this.qualificationid,
          'qualificationname': this.qualificationform.get('qualificationname').value,
          'qualificationyrs': this.qualificationform.get('qualificationyrs').value,
          'inactive': this.qualificationform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckQualification(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.qualificationform.get('qualificationid').value > 0) {
                //Update Department
                this.fnSetQualification(data);
              }
              else {
                //Insert Department
                this.fnInsertQualification(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.qualificationform.controls['qualificationname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertQualification(data) {

    this.appService.fnInsertQualification(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var qualificationid = 0;
          if (result.length > 0) {
            qualificationid = result[0].qualificationid
          }

          this.fnInsertActivityLog('INSERT', 0, qualificationid);

          this.toastr.success('', "The new qualification has been added successfully.Insert Successfully");
          this.emitData.emit(qualificationid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetQualification(data) {

    this.appService.fnSetQualification(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Qualification has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.qualificationid, data.qualificationname);

          this.emitData.emit(this.qualificationid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    this.qualificationform.setValue({
      qualificationid: deptBO.qualificationid,
      qualificationname: deptBO.qualificationname,
      qualificationyrs: deptBO.qualificationyrs,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res)=>{
        },
        (err)=>{

        });

    } catch {

    }
  }

  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
