import { Component, OnInit } from '@angular/core';
import { DhukanDataService } from '../services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
declare var google;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Dashboard";


  totalmembers: number = 0;
  totaltaxpayer: number = 0;
  totalvmjpr: number = 0;

  totalmembersmale: number = 0;
  totalmembersfemale: number = 0;

  totalvotermale: number = 0;
  totalvoterfemale: number = 0;

  totaleligiblemale: number = 0;
  totaleligiblefemale: number = 0;

  totalstudentmale: number = 0;
  totalstudentfemale: number = 0;

  totaltaxbalance: number = 0;
  totaltaxadvance: number = 0;

  todayreceivedamount: number = 0;
  thismonthreceivedamount: number = 0;
  thisyearreceivedamount: number = 0;


  //Chart
  view: any[] = [360, 400];
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  //Chartdata
  totalvoterColl: any[] = [];
  totalstudentColl: any[] = [];




  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService,
    private loginService: LoginDataService, private datePipe: DatePipe) {

    this.fnServiceChanges();
  }
  

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }



  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
    this.isview = true;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnOnInitForm() {
    this.fngetDasboard();



  }

  ngOnInit() {

    if (this.loginService.fnCheckUser()) {
      this.fnCheckPrivilage();
      if (this.isview) {
        this.isloading = true;
        this.fnOnInitForm();
      }
      else {
        this.fnUserFailedForm();
      }
    }
    else {
      this.loginService.fnGetUserRoleList();
    }

  }


  fngetDasboard() {

    var data = {

    }
    this.appService.fnGetDashboardAdmin(data)
      .subscribe(
        (res) => {
          if (res.json().status == 200) {
            var _res = res.json().result;

            var summaryColl = _res[0];

            if (summaryColl.length > 0) {
              this.totalmembers = summaryColl[0].totalmembers;
              this.totaltaxpayer = summaryColl[0].totaltaxpayer;
              this.totalvmjpr = summaryColl[0].totalvmjpr;

              this.totalmembersmale = summaryColl[0].totalmembersmale;
              this.totalmembersfemale = summaryColl[0].totalmembersfemale;

              this.totalvotermale = summaryColl[0].totalvotermale;
              this.totalvoterfemale = summaryColl[0].totalvoterfemale;

              this.totaleligiblemale = summaryColl[0].totaleligiblemale;
              this.totaleligiblefemale = summaryColl[0].totaleligiblefemale;

              this.totalstudentmale = summaryColl[0].totalstudentmale;
              this.totalstudentfemale = summaryColl[0].totalstudentfemale;

              this.totaltaxbalance = summaryColl[0].totaltaxbalance;
              this.totaltaxadvance = summaryColl[0].totaltaxadvance;


              this.todayreceivedamount = summaryColl[0].todayreceivedamount;
              this.thismonthreceivedamount = summaryColl[0].thismonthreceivedamount;
              this.thisyearreceivedamount = summaryColl[0].thisyearreceivedamount;

              this.totalvoterColl = [];
              this.totalvoterColl.push({ 'name': "Male "+this.totalvotermale , "value": this.totalvotermale })
              this.totalvoterColl.push({ 'name': "Female "+this.totalvoterfemale, "value": this.totalvoterfemale })

              this.totalstudentColl = [];
              this.totalstudentColl.push({ 'name':  "Male "+this.totalstudentmale, "value": this.totalstudentmale })
              this.totalstudentColl.push({ 'name': "Female "+this.totalstudentfemale, "value": this.totalstudentfemale })

            }

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnEditCustomer(deptBO) {
    try {
      if (this.isedit) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': deptBO.customerid,
          }
        }

        this.router.navigate(['/CustomerView'], navigationExtras);
      }

    } catch (ex) {
      // this.fnShowErrorMsg(ex);
    }
  }
  fnLoadGeoLocation() {


  }
  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
}

  fnShowOrder(type) {
    try {
      if (this.isedit) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'filterstatus': type,
          }
        }

        this.router.navigate(['/Orders'], navigationExtras);
      }

    } catch (ex) {
      // this.fnShowErrorMsg(ex);
    }
  }

}
