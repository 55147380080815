import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,AfterViewChecked {
 
  //Save Validation
  submitted:boolean;
  successfully:boolean;
 
  userColl:any=[];
 
   loginform=new FormGroup({
     username:new FormControl('',Validators.required),         
     pwd:new FormControl('',Validators.required),
 });
 
 
   constructor(private loginService: LoginDataService,private appService: DhukanDataService,
     private toastr: ToastrService,public router: Router) { }
 
   ngOnInit() {
     this.fnInitializeFoodSymbolFormGroup();
     
     //setTimeout(() => this.appService.islogin = false);
        this.loginService.islogin=false;
   }
 
     ngAfterViewChecked(){
       this.loginService.islogin = true;
     }
 
   fnInitializeFoodSymbolFormGroup(){
     this.loginform.setValue({
       username:'',      
       pwd:''
     })
  }
  
  fnResetValidation() {

    if (this.loginform.get('username')) {    
      this.loginform.controls['username'].setErrors(null);
    }

  }
 
   fnLogin(){
     try {
       this.fnResetValidation();
  
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.loginform.valid){
         var data = {
           'username':this.loginform.get('username').value, 
           'pwd':this.loginform.get('pwd').value, 
         }
         this.appService.getUserlogin(data)
         .subscribe(
           (res)=>{   
                        
             if(res.json().status===200){
             
               this.userColl=res.json().result;  
              console.log(this.userColl)
              
               if(this.userColl){
 
                 if(this.userColl.length>0){
 
                   sessionStorage.setItem('rmstoken', this.userColl[0].userid);   
                     this.loginService.fnGetUserRoleList();
                     this.loginService.islogin=false;
 
                     if(this.userColl[0].isscreencheck===1){
                       var _screen="";
                       _screen=this.userColl[0].landingscreen;
 
                       this.fnInsertActivityLog('LOGIN',this.userColl[0].loginuserid,this.userColl[0].username);
                      
                                          
                         this.router.navigate(['/']).then(()=>{
                           window.location.reload();
                         });
                       }
 
                  
                 }else{
                   this.loginform.controls['username'].setErrors({'notfound': true});
                 }
 
               }
 
             }   
                      
         },
         (err)=>{          
           this.toastr.error('Error', err);
         })
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
   }
 
 
   
  fnInsertActivityLog(action,refno,desc){
   try{
     var data={
       logtype:action,
       logat:"LOGIN",
       refnum:refno,
       logdescr:desc,
       logby:refno,
     }
   
 
    //  this.appService.fnInsertActivity(data).subscribe(
    //    (res)=>{
    //    },
    //    (err)=>{
      
    //    });
 
   }catch{
 
   }
 }
 
 
   
   private  fnShowErrorMsg(ex){
     this.toastr.warning('Internal Error', ex);       
   }
 
 }
 