import { DatePipe } from '@angular/common';
import { EventEmitter, Input, ViewChildren } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-addtax',
  templateUrl: './addtax.component.html',
  styleUrls: ['./addtax.component.css']
})
export class AddtaxComponent implements OnInit {

  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  taxColl: any = [];


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Mohalla";

  taxform = new FormGroup({
    taxid: new FormControl(null),
    memberid: new FormControl(null, Validators.required),
    familyid: new FormControl(null, Validators.required),
    transdate: new FormControl(null, Validators.required),
    taxamt: new FormControl(null),
    paidamt: new FormControl(null),
    descr: new FormControl(null),
    refno: new FormControl(null),
    isopening: new FormControl(false),
  });


  @Input() taxid: number = 0;
  @Input() memberid: number = null;
  @Input() familyid: number = null;
  @Input() isopening: boolean = false;

  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, private appService: DhukanDataService, private route: ActivatedRoute, private datePipe: DatePipe,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.taxid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.taxform.reset();
      this.fnInitializeTaxFormGroup();

      if (this.taxid > 0) {
        this.fngetTax();
      }



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.taxid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeTaxFormGroup() {
    this.taxform.setValue({
      taxid: 0,
      memberid: this.memberid,
      familyid: this.familyid,
      transdate: new Date(),
      taxamt: null,
      paidamt: null,
      descr: null,
      refno: null,
      isopening: this.isopening,
    })
  }





  //Get 
  fngetTax() {
    var data = {
      'taxid': this.taxid,
    }
    this.appService.getTax(data)
      .subscribe(
        (res) => {
          this.taxColl = res.json().result;
          var editdata = this.taxColl[0];
        console.log(editdata)

          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }





  //Save Btn Click
  fnSaveBtnClick() {
    try {
     
      this.submitted = true;
      this.successfully = false;
     
      //Validate
      if (this.taxform.valid) {
      
        //Check
        var data = {
          'taxid': this.taxid,
          'memberid': this.taxform.get('memberid').value,
          'familyid': this.taxform.get('familyid').value,
          'transdate': this.fnFormatDatetime(this.taxform.get('transdate').value),
          'taxamt': this.taxform.get('taxamt').value,
          'paidamt': this.taxform.get('paidamt').value,
          'descr': this.taxform.get('descr').value,
          'refno': this.taxform.get('refno').value,          
          'isopening': this.taxform.get('isopening').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckTax(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.taxform.get('taxid').value > 0) {
                //Update Tax
                this.fnSetTax(data);
              }
              else {
                //Insert Tax
                this.fnInsertTax(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.taxform.controls['transdate'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
     
    } catch (ex) {
 
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertTax(data) {

    this.appService.fnInsertTax(data).subscribe(
      (res) => {

        if (res.json().status === 200) {

          var result = res.json().result;

          var taxid = 0;
          if (result.length > 0) {
            taxid = result[0].taxid
          }

          this.fnInsertActivityLog('INSERT', 0, taxid);

          this.toastr.success('', "The new Tax has been added successfully.Insert Successfully");
          this.emitData.emit(taxid);
          this.activeModal.close(true);

        }
        else{
          console.log(res.json())
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetTax(data) {

    this.appService.fnSetTax(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Tax has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.taxid, data.taxname);

          this.emitData.emit(this.taxid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }


  private fnEditMode(deptBO) {

    deptBO.transdate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.transdate));

    this.taxform.setValue({
      taxid: deptBO.taxid,
      memberid: deptBO.memberid,
      familyid: deptBO.familyid,
      transdate: deptBO.transdate,
      taxamt: deptBO.taxamt,
      paidamt: deptBO.paidamt,
      descr: deptBO.descr,
      refno: deptBO.refno,
      isopening: deptBO.isopening,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }


  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
