import { EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { AddmemberComponent } from '../../member/addmember/addmember.component';
import { AddmembershortComponent } from '../../member/addmembershort/addmembershort.component';
import { EditaddressComponent } from '../../member/editaddress/editaddress.component';
import { EditfamilypopComponent } from '../editfamilypop/editfamilypop.component';
import { PrintfamilyComponent } from '../printfamily/printfamily.component';

@Component({
  selector: 'app-editfamily',
  templateUrl: './editfamily.component.html',
  styleUrls: ['./editfamily.component.css']
})
export class EditfamilyComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  isaddmembermode: boolean = false;
  iseditmembermode: boolean = false;
  isdeletemembermode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  familyColl: any = [];
  relationshipColl: any = [];
  familychildColl: any = [];
  familychild2Coll: any = [];
  familychild4Coll: any = [];

  streetColl: any = [];
  cityColl: any = [];

  memberBO: any = {};

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Family";
  entrymode: String = "";
  rtype: String = "";

  familyform = new FormGroup({
    familyid: new FormControl(null),
    familyno: new FormControl('', Validators.required),
    familynickname: new FormControl(''),
    memberid: new FormControl(null, Validators.required),
    parentmemberid: new FormControl(null),
    relationshipid: new FormControl(null, Validators.required),
    isfamilyhead: new FormControl(false),
    orderno: new FormControl(null),
    inactive: new FormControl(false),
  });


  @Input() familyid: number = 0;
  @Input() familyname: any = '';
  @Output() emitData = new EventEmitter();


  constructor(private toastr: ToastrService, public router: Router, private productFindService: NgbModal, private appService: DhukanDataService, private route: ActivatedRoute,
    private loginService: LoginDataService, private confirmationDialogService: ConfirmationDialogService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.familyid = params.id
      }
      if (params.entrymode !== undefined) {
        this.entrymode = params.entrymode
      }
      if (params.rtype !== undefined) {
        this.rtype = params.rtype
      }
      
      
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      this.isaddmode = true;
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    //this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {

      this.familyform.reset();
      this.fnInitializeFamilyFormGroup();

      if (this.familyid > 0) {
        this.fngetFamily();
      }

      this.fngetRelationShip();
      this.fngetStreet();
      this.fngetCity();


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fngetRelationShip() {
    var data = {
      'relationshipid': 0,
    }
    this.appService.getRelationship(data)
      .subscribe(
        (res) => {
          this.relationshipColl = res.json().result;

          if (this.familyid === 0) {
            for (let abo of this.relationshipColl) {
              if (abo.isfamilyhead) {
                this.familyform.patchValue({ 'relationshipid': abo.relationshipid })
              }
            }
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetStreet() {
    var data = {
      'streetid': 0,
    }
    this.appService.getStreet(data)
      .subscribe(
        (res) => {
          this.streetColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetCity() {
    var data = {
      'cityid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.familyid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

   this.isaddmembermode = this.loginService.fnCheckUserRoleAction("Member", 'CREATE')
   this.iseditmembermode = this.loginService.fnCheckUserRoleAction("Member", 'ALTER')
   this.isdeletemembermode = this.loginService.fnCheckUserRoleAction("Member", 'DELETE')

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeFamilyFormGroup() {
    this.familyform.setValue({
      familyid: 0,
      familyno: 'NEW',
      familynickname: '',
      memberid: null,
      parentmemberid: null,
      relationshipid: null,
      isfamilyhead: true,
      orderno: null,
      inactive: false
    })
  }





  //Get 
  fngetFamily() {
    var data = {
      'familyid': this.familyid,
    }
    this.appService.getFamily(data)
      .subscribe(
        (res) => {

          this.familyColl = res.json().result;
          var editdata = this.familyColl[0];
          this.fnEditMode(editdata);

          this.memberBO = editdata;

          this.fngetFamilyByMemberid()
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetFamilyByMemberid() {
    var data = {
      'memberid': this.familyform.get('memberid').value,
    }
    this.appService.getFamilyByMemberid(data)
      .subscribe(
        (res) => {

          this.familychildColl = res.json().result[0];
          this.familychild2Coll = res.json().result[1];

          this.familychild4Coll = res.json().result[3];
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  private fnEditMode(deptBO) {

    this.familyform.setValue({
      familyid: deptBO.familyid,
      familyno: deptBO.familyno,
      familynickname: deptBO.familynickname,
      memberid: deptBO.memberid,
      parentmemberid: deptBO.parentmemberid,
      relationshipid: deptBO.relationshipid,
      isfamilyhead: deptBO.isfamilyhead,
      orderno: deptBO.orderno,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }

  //Close
  fnCloseFamily() {
    try {
      if (this.rtype === "TAX") {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': this.memberBO.familyid,           
          }
        }
  
        this.router.navigate(['/ViewTax'], navigationExtras);
      } else {
        this.router.navigate(['/Family']);
      }

     
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnReloadFamily(id) {
    try {

      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': id,
        }
      }

      this.router.navigate(['/AddFamily'], navigationExtras).then(() => {
        window.location.reload()
      });

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnAddMember() {
    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static' ,keyboard: false});
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.parentmemberid = 0;
    modalRef.componentInstance.familynickname = this.familyform.get('familynickname').value;
    modalRef.componentInstance.entrymode = this.entrymode;
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      if (this.familyid === 0 && receivedEntry > 0) {
        this.fnReloadFamily(receivedEntry);
      }
    });
  }

  fnEditMember(memberid) {
    if (!this.iseditmembermode) {
      return
    }
    
    const modalRef = this.productFindService.open(AddmemberComponent, { size: <any>'xl', backdrop: 'static',keyboard: false, });
    modalRef.componentInstance.memberid = memberid; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetFamily();
    });
  }


  fnAddChildMember() {

    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static',keyboard: false, });   
    modalRef.componentInstance.parentmemberid = this.memberBO.memberid;
    modalRef.componentInstance.familynickname = this.memberBO.familynickname;
    modalRef.componentInstance.parentmemberBO = this.memberBO;
   // modalRef.componentInstance.fathername = this.memberBO.fathername;
    modalRef.componentInstance.doorno = this.memberBO.doorno;
    modalRef.componentInstance.streetid = this.memberBO.streetid;
    modalRef.componentInstance.cityid = this.memberBO.cityid;
    modalRef.componentInstance.parentmohallahid= this.memberBO.mohallahid
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetFamily();
      // if (this.familyid > 0 && receivedEntry > 0) {
      //   this.fngetFamilyByMemberid();
      // }
    });
  }


  
  fnAddWifeMember() {
   

    const modalRef = this.productFindService.open(AddmembershortComponent, { size: <any>'l', backdrop: 'static',keyboard: false, });   
    modalRef.componentInstance.parentmemberid = this.memberBO.memberid;
    modalRef.componentInstance.familynickname = this.memberBO.familynickname;
    modalRef.componentInstance.parentmemberBO = this.memberBO;
    modalRef.componentInstance.iswife =true;
    
    modalRef.componentInstance.doorno = this.memberBO.doorno;
    modalRef.componentInstance.streetid = this.memberBO.streetid;
    modalRef.componentInstance.cityid = this.memberBO.cityid;


    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetFamily();

      // if (this.familyid > 0 && receivedEntry > 0) {
      //   this.fngetFamilyByMemberid();
      // }

    });
  }


  fnEditFamilyPop(familyid) {
    if (!this.iseditmembermode) {
      return
    }
    const modalRef = this.productFindService.open(EditfamilypopComponent, { size: <any>'xs', backdrop: 'static',keyboard: false });    
    modalRef.componentInstance.familyid = familyid;
    modalRef.componentInstance.ngOnInit();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
     
        this.fngetFamily();
      
    });
  }
  public fnDeleteConfirm(pBO) {
    try {

      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Family?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteFamily(pBO)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditMemberAddress(memberid) {
    if (!this.iseditmembermode) {
      return
    }
    
    const modalRef = this.productFindService.open(EditaddressComponent, { size: <any>'l', backdrop: 'static',keyboard: false, });
    modalRef.componentInstance.memberid = memberid; 
    modalRef.componentInstance.ngOnInit();   
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetFamily();
    });
  }

  private fnDeleteFamily(pBO) {
    var deptid = pBO.familyid
    var data = {
      'familyid': deptid,
    }
    this.appService.fnDeleteFamily(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            var result = res.json().result;


            var checkresult = '';
            if (result.length > 0) {
              checkresult=result[0].checkresult
            }
            
            if (checkresult === 'DONE') {
              this.toastr.success('', " The " + pBO.membername + " family has been deleted successfully.");
              this.fngetFamily();
            }
            else {
              this.toastr.error('', checkresult);
            }

            
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  

  fnPrintSlip() {

    const modalRef = this.productFindService.open(PrintfamilyComponent, { size: <any>'m', backdrop: 'static' });    
    modalRef.componentInstance.memberBO = this.memberBO;
    modalRef.componentInstance.familychildColl=this.familychild4Coll
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
      
    });
  }

  
  fnViewTaxDetails() {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.memberBO.familyid,
          'rtype':'FAMILY'
        }
      }

      this.router.navigate(['/ViewTax'], navigationExtras)
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res)=>{
      //   },
      //   (err)=>{

      //   });

    } catch {

    }
  }




  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }


}
