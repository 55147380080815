import { Component, OnInit, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { AddprofessionComponent } from '../../profession/addprofession/addprofession.component';
import { AddqualificationComponent } from '../../qualification/addqualification/addqualification.component';
import { AddinstituteComponent } from '../../institute/addinstitute/addinstitute.component';
import { AddmohallaComponent } from '../../mohalla/addmohalla/addmohalla.component';
import { AddstreetComponent } from '../../street/addstreet/addstreet.component';
import { AddcityComponent } from '../../city/addcity/addcity.component';


@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.css']
})
export class AddmemberComponent implements OnInit {
  @ViewChildren('inputfirst') firstipcontrol;
  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  isstreetaddmode: boolean = false;
  iscityaddmode: boolean = false;
  ismohallaaddmode: boolean = false;
  isqualificationaddmode: boolean = false;
  isprofessionaddmode: boolean = false;


  //Save Validation
  submitted: boolean;
  successfully: boolean;


  isdisablerelationship: boolean = false;
  isdisablegender: boolean = false;
  isdisablemaritalstatus: boolean = false;

  //Edit Get Values
  memberColl: any = [];
  mohallaColl: any = [];
  instituteColl: any = [];
  professionColl: any = [];
  qualificationColl: any = [];
  relationshipColl: any = [];
  genderColl: any = [];
  maritalColl: any = [];
  streetColl: any = [];
  cityColl: any = [];
  yearColl: any = [];
  bloodgroupColl: any = [];

  memberBO: any = {}

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress : boolean = false;
  formname: String = "Member";

  saveloading:boolean=false

  memberform = new FormGroup({
    memberid: new FormControl(null),
    memberno: new FormControl('',),
    membername: new FormControl('', Validators.required),
    membernametamil: new FormControl(''),
    fathername: new FormControl(''),
    doorno: new FormControl(''),
    streetid: new FormControl(null),
    cityid: new FormControl(null),
    isownhouse: new FormControl(false),
    doornopresent: new FormControl(''),
    presentstreetid: new FormControl(null),
    presentcityid: new FormControl(null),
    isownhousepresent: new FormControl(false),
    gender: new FormControl('', Validators.required),
    maritalstatus: new FormControl('', Validators.required),
    dateofbirth: new FormControl(null, Validators.required),
    placeofbirth: new FormControl(''),
    isstudent: new FormControl(false),
    qualificationid: new FormControl(null),
    year: new FormControl(null),
    instituteid: new FormControl(null),
    qualificationid1: new FormControl(null),
    year1: new FormControl(null),
    instituteid1: new FormControl(null),
    qualificationid2: new FormControl(null),
    year2: new FormControl(null),
    instituteid2: new FormControl(null),
    qualificationid3: new FormControl(null),
    year3: new FormControl(null),
    instituteid3: new FormControl(null),
    professionid: new FormControl(null),
    professionplace: new FormControl(''),
    annualincome: new FormControl(null),
    mohallaid: new FormControl(null, Validators.required),
    mobile: new FormControl(''),
    email: new FormControl(''),
    adhaarno: new FormControl(''),
    isvoter: new FormControl(false),
    isphy: new FormControl(false),
    photopicurl: new FormControl(''),
    memberimage: new FormControl(null),
    relationshipid: new FormControl(null, Validators.required),
    isdifferentaddress: new FormControl(false),
    isusepermanataddress: new FormControl(false),
    placeofbirthcityid: new FormControl(null),
    professionplacecityid: new FormControl(null),
    cardno: new FormControl(''),
    istemp: new FormControl(false),
    fathermohallaid: new FormControl(null),
    isdeathfather: new FormControl(false),
    isdeath: new FormControl(false),
    isskiptax: new FormControl(false),
    currdate: new FormControl(null),
    bloodgroup: new FormControl(''),
    remarks: new FormControl(''),
    inactive: new FormControl(false),    
  });

  relationshipname: any = '';
  qualificationyrs: any = 0;

  @Input() memberid: number = 0;
  @Input() membername: any = '';
  @Input() familynickname: any = "";
  @Input() parentmemberid: number = 0;
  @Input() parentmemberBO: any = {};
  @Input() entrymode: any = "";
  @Output() emitData = new EventEmitter();

  maxDate = new Date();

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  age: number = 0;

  constructor(private toastr: ToastrService, private appService: DhukanDataService, private productFindService: NgbModal, private route: ActivatedRoute, private datePipe: DatePipe,
    private loginService: LoginDataService, private activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) {
    // this.route.queryParams.subscribe(params => {
    //   if (params.id !== undefined) {
    //     this.memberid = params.id
    //   }
    // });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }
  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    ////this.firstipcontrol.first.nativeElement.focus();
  }
  fnOnInitForm() {
    try {
      this.isdisablerelationship = false;

      this.memberform.reset();
      this.fnInitializeMemberFormGroup();

      if (this.memberid > 0) {
        this.fngetMember();
      }
      this.fngetMohalla();
      this.fngetProfession();
      this.fngetQualification();
      this.fngetInstitute();
      this.fngetRelationship();
      this.fngetGender();
      this.fngetMaritalstatus();
      this.fngetStreet();
      this.fngetCity();
      this.fnSetAddressByParent();
      this.fnGetBloodGroup()
      


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.memberid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    this.isstreetaddmode = this.loginService.fnCheckUserRoleAction("Street", 'CREATE');
    this.iscityaddmode = this.loginService.fnCheckUserRoleAction("City", 'CREATE');
    this.ismohallaaddmode = this.loginService.fnCheckUserRoleAction("Mohalla", 'CREATE');
    this.isqualificationaddmode = this.loginService.fnCheckUserRoleAction("Qualification", 'CREATE');
    this.isprofessionaddmode = this.loginService.fnCheckUserRoleAction("Profession", 'CREATE');

    return _check;
  }
  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeMemberFormGroup() {

    this.memberform.setValue({

      memberid: 0,
      memberno: 'NEW',
      membername: '',
      membernametamil: '',
      fathername: '',
      doorno: '',
      streetid: null,
      cityid: null,
      isownhouse: false,
      doornopresent: '',
      presentstreetid: null,
      presentcityid: null,
      isownhousepresent: false,
      gender: '',
      maritalstatus: '',
      dateofbirth: null,
      placeofbirth: '',
      isstudent: false,
      qualificationid: null,
      year: null,
      instituteid: null,
      qualificationid1: null,
      year1: null,
      instituteid1: null,
      qualificationid2: null,
      year2: null,
      instituteid2: null,
      qualificationid3: null,
      year3: null,
      instituteid3: null,
      professionid: null,
      professionplace: '',
      annualincome: null,
      mohallaid: null,
      mobile: '',
      email: '',
      adhaarno: null,
      isvoter: false,
      isphy: false,
      photopicurl: '',
      memberimage: null,
      relationshipid: null,
      isdifferentaddress: false,
      isusepermanataddress: false,
      placeofbirthcityid: null,
      professionplacecityid: null,
      cardno: '',
      istemp: false,
      fathermohallaid: null,
      isdeathfather: false,
      isdeath: false,
      isskiptax: false,
      currdate: new Date(),
      bloodgroup: '',
      remarks: '',
      inactive: false,
    })
  }


  fngetRelationship() {
    var data = {
      'relationshipid': 0,
    }
    this.appService.getRelationship(data)
      .subscribe(
        (res) => {
          this.relationshipColl = res.json().result;

          if (this.memberid === 0 && this.parentmemberid === 0) {
            for (let abo of this.relationshipColl) {
              if (abo.isfamilyhead) {
                this.isdisablerelationship = true;
                this.memberform.patchValue({ 'relationshipid': abo.relationshipid })
                this.relationshipname = abo.relationshipname

                this.isdisablegender = true;
                this.isdisablemaritalstatus = true;
                this.fnChangeRelationShip()
              }
            }
          }



        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetMaritalstatus() {
    this.maritalColl = [];
    this.maritalColl.push({ value: 'M', displayname: 'Married' });
    this.maritalColl.push({ value: 'U', displayname: 'Unmarried' });
    this.maritalColl.push({ value: 'D', displayname: 'Divorce' })


  }

  fngetGender() {
    this.genderColl = [];
    this.genderColl.push({ value: 'M', displayname: 'Male' });
    this.genderColl.push({ value: 'F', displayname: 'Female' })

  }

  fngetStreet() {
    var data = {
      'streetid': 0,
    }
    this.appService.getStreet(data)
      .subscribe(
        (res) => {
          this.streetColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetCity() {
    var data = {
      'cityid': 0,
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnGetBloodGroup(){
    this.bloodgroupColl=[]

    this.bloodgroupColl.push({ name: 'A+', displayname: 'A+' })
    this.bloodgroupColl.push({ name: 'A-', displayname: 'A-' })
    this.bloodgroupColl.push({name:'B+',displayname:'B+'})
    this.bloodgroupColl.push({name:'B-',displayname:'B-'})
    this.bloodgroupColl.push({name:'O+',displayname:'O+'})
    this.bloodgroupColl.push({ name: 'O-', displayname: 'O-' })
    this.bloodgroupColl.push({ name: 'AB+', displayname: 'AB+' })
    this.bloodgroupColl.push({ name: 'AB-', displayname: 'AB-' })
    
    
  }
  

  fngetMohalla() {
    var data = {
      'mohallaid': 0,
    }
    this.appService.getMohalla(data)
      .subscribe(
        (res) => {
          this.mohallaColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetInstitute() {
    var data = {
      'instituteid': 0,
    }
    this.appService.getInstitute(data)
      .subscribe(
        (res) => {
          this.instituteColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }
  fngetProfession() {
    var data = {
      'professionid': 0,
    }
    this.appService.getProfession(data)
      .subscribe(
        (res) => {
          this.professionColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetQualification() {
    var data = {
      'qualificationid': 0,
    }
    this.appService.getQualification(data)
      .subscribe(
        (res) => {
          this.qualificationColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnGetYear() {
    this.yearColl = [];

    if (this.qualificationyrs > 0) {
      this.yearColl.push({ displayname: '1', value: 1 })
    }
    if (this.qualificationyrs > 1) {
      this.yearColl.push({ displayname: '2', value: 2 })
    }
    if (this.qualificationyrs > 2) {
      this.yearColl.push({ displayname: '3', value: 3 })
    }
    if (this.qualificationyrs > 3) {
      this.yearColl.push({ displayname: '4', value: 4 })
    }
    if (this.qualificationyrs > 4) {
      this.yearColl.push({ displayname: '5', value: 5 })
    }
  }

  fnCalculateAge() {

    if(this.memberform.get('dateofbirth').value===null){
      this.age = 0
    }else{
      let timeDiff = Math.abs(Date.now() - this.memberform.get('dateofbirth').value);
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    }
  
   
    this.fnSetValidattor();

  }
   
  //Get 
  fngetMember() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getMember(data)
      .subscribe(
        (res) => {
          this.memberColl = res.json().result;
          var editdata = this.memberColl[0];

          this.memberBO = editdata
          this.fnEditMode(editdata);

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  fnSetAddressByParent() {

    if (this.parentmemberid > 0 && this.memberid === 0) {

      this.memberform.patchValue({
        'doorno': this.parentmemberBO.doorno,
        'streetid': this.parentmemberBO.streetid,
        'cityid': this.parentmemberBO.cityid,
        'isownhouse': this.parentmemberBO.isownhouse,
        'isusepermanataddress': this.parentmemberBO.isusepermanataddress,
        'isownhousepresent': this.parentmemberBO.isownhousepresent,

      })

    }

  }

  fnSetValidattor(){

    if (this.memberform.get('isdeath').value) {
      
      this.memberform.controls['dateofbirth'].clearValidators();
      this.memberform.controls['dateofbirth'].setErrors(null);
      this.memberform.controls['dateofbirth'].setValidators(null);

      this.memberform.controls['adhaarno'].clearValidators();
      this.memberform.controls['adhaarno'].setErrors(null);
      this.memberform.controls['adhaarno'].setValidators(null);
      
      return
    }

    if (this.age > 8) {         
      this.memberform.controls['adhaarno'].setValidators(Validators.required);        

      if (!this.memberform.get('adhaarno').value) {
        this.memberform.controls['adhaarno'].setErrors({ 'required': true });
      }

    }
    else{
      this.memberform.controls['adhaarno'].clearValidators();
      this.memberform.controls['adhaarno'].setErrors(null);
      this.memberform.controls['adhaarno'].setValidators(null);
    }

    this.fnResetValidation()

  }
  fnSetValidation() {
    let _ischeck = false;
    if (this.memberform.get('dateofbirth').value != null) {
      if (this.loginService.fnCompareDateValidation(this.memberform.get('currdate').value, this.memberform.get('dateofbirth').value)) {
        this.memberform.controls['dateofbirth'].setErrors({ 'notfound': true });
        _ischeck = true;
      }
    }

    return _ischeck;
  }

  
  fnResetValidation() {
    if (this.loginService.fnCheckFornControlNotfound(this.memberform.get('dateofbirth'))) {
      this.memberform.controls['dateofbirth'].setErrors(null);
    }
  
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {

      if (this.saveloading) {
        return
      }
     // this.fnResetValidation();

      this.fnSetValidattor();

      this.saveloading=true

      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.memberform.valid) {

        if (this.fnSetValidation()) {
          this.saveloading=false
          return;
        }


        if (this.entrymode === 'TEMP') {
          this.memberform.patchValue({ 'istemp': true })
        }

        //Check
        var data = {

          'memberid': this.memberid,
          'memberno': this.memberform.get('memberno').value,
          'membername': this.memberform.get('membername').value,
          'membernametamil': this.memberform.get('membernametamil').value,
          'fathername': this.memberform.get('fathername').value,
          'doorno': this.memberform.get('doorno').value,
          'streetid': this.memberform.get('streetid').value,
          'cityid': this.memberform.get('cityid').value,
          'isownhouse': this.memberform.get('isownhouse').value,
          'doornopresent': this.memberform.get('doornopresent').value,
          'presentstreetid': this.memberform.get('presentstreetid').value,
          'presentcityid': this.memberform.get('presentcityid').value,
          'isownhousepresent': this.memberform.get('isownhousepresent').value,
          'gender': this.memberform.get('gender').value,
          'maritalstatus': this.memberform.get('maritalstatus').value,
          'dateofbirth': this.fnFormatDatetime(this.memberform.get('dateofbirth').value),
          'placeofbirth': this.memberform.get('placeofbirth').value,
          'isstudent': this.memberform.get('isstudent').value,
          'qualificationid': this.memberform.get('qualificationid').value,
          'year': this.memberform.get('year').value,
          'instituteid': this.memberform.get('instituteid').value,
          'qualificationid1': this.memberform.get('qualificationid1').value,
          'year1': this.memberform.get('year1').value,
          'instituteid1': this.memberform.get('instituteid1').value,
          'qualificationid2': this.memberform.get('qualificationid2').value,
          'year2': this.memberform.get('year2').value,
          'instituteid2': this.memberform.get('instituteid2').value,
          'qualificationid3': this.memberform.get('qualificationid3').value,
          'year3': this.memberform.get('year3').value,
          'instituteid3': this.memberform.get('instituteid3').value,
          'professionid': this.memberform.get('professionid').value,
          'professionplace': this.memberform.get('professionplace').value,
          'annualincome': this.memberform.get('annualincome').value,
          'mohallaid': this.memberform.get('mohallaid').value,
          'mobile': this.memberform.get('mobile').value,
          'email': this.memberform.get('email').value,
          'adhaarno': this.memberform.get('adhaarno').value,
          'isvoter': this.memberform.get('isvoter').value,
          'isphy': this.memberform.get('isphy').value,
          'memberimage': this.strImage,
          'photopicurl': this.picurl,
          'relationshipid': this.memberform.get('relationshipid').value,
          'parentmemberid': this.parentmemberid,
          'familynickname': this.familynickname,
          'isdifferentaddress': this.memberform.get('isdifferentaddress').value,
          'isusepermanataddress': this.memberform.get('isusepermanataddress').value,
          'placeofbirthcityid': this.memberform.get('placeofbirthcityid').value,
          'professionplacecityid': this.memberform.get('professionplacecityid').value,
          'cardno': this.memberform.get('cardno').value,
          'istemp': this.memberform.get('istemp').value,
          'isdeathfather': this.memberform.get('isdeathfather').value,
          'isskiptax': this.memberform.get('isskiptax').value,
          'fathermohallaid': this.memberform.get('fathermohallaid').value,
          'isdeath': this.memberform.get('isdeath').value,
          'bloodgroup': this.memberform.get('bloodgroup').value,
          'remarks': this.memberform.get('remarks').value,
          'inactive': this.memberform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }

        this.appService.fnCheckMember(data).subscribe(
          (res) => {
            
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.memberform.get('memberid').value > 0) {
                //Update Department
                this.fnSetMember(data);
              }
              else {
                //Insert Department
                this.fnInsertMember(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.memberform.controls['adhaarno'].setErrors({ 'notfound': true });
              }

              this.saveloading=false
            }
          },
          (err) => {
            this.saveloading=false
            this.toastr.error('Error', err);
          });
      }
      else{
     
        this.saveloading=false
      }
    } catch (ex) {
      this.saveloading=false
      console.log(ex)
      this.fnShowErrorMsg(ex);
    }
  }



  //Insert
  fnInsertMember(data) {

    this.appService.fnInsertMember(data).subscribe(
      (res) => {
        this.saveloading=false
        if (res.json().status === 200) {

          var result = res.json().result;

          var familyid = 0;
          if (result.length > 0) {
            familyid = result[0].familyid
          }

          this.fnInsertActivityLog('INSERT', 0, familyid);

          this.toastr.success('', "The new member has been added successfully.Insert Successfully");
          this.emitData.emit(familyid);
          this.activeModal.close(true);

        }
      },
      (err) => {
        this.saveloading=false
        this.toastr.error('Error', err);
      });

  }


  //Set
  fnSetMember(data) {

    this.appService.fnSetMember(data).subscribe(
      (res) => {
        this.saveloading=false
        if (res.json().result != "") {
          this.toastr.success('', "The Member has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.memberid, data.membername);

          this.emitData.emit(this.memberid);
          this.activeModal.close(true);;
        }
      },
      (err) => {
        this.saveloading=false
        this.toastr.error('Error', err);
      });

  }

  //AddQualification
  private fnAddQualification() {

    const modalRef = this.productFindService.open(AddqualificationComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetQualification();

      if (receivedEntry > 0) {
        this.memberform.patchValue({ qualificationid: receivedEntry })
      }
    });

  }

  private fnAddInstitute() {

    const modalRef = this.productFindService.open(AddinstituteComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetInstitute();

      if (receivedEntry > 0) {
        this.memberform.patchValue({ instituteid: receivedEntry })
      }
    });

  }

  private fnAddInstituteMore(type) {

    const modalRef = this.productFindService.open(AddinstituteComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetInstitute();

      if (receivedEntry > 0) {
        if (type === "1") {
          this.memberform.patchValue({ instituteid1: receivedEntry })
        }
        else  if (type === "2") {
          this.memberform.patchValue({ instituteid2: receivedEntry })
        }
        else  if (type === "3") {
          this.memberform.patchValue({ instituteid3: receivedEntry })
        }
        
      }
    });

  }

  private fnAddProfession() {

    const modalRef = this.productFindService.open(AddprofessionComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetProfession();

      if (receivedEntry > 0) {
        this.memberform.patchValue({ professionid: receivedEntry })
      }
    });

  }

  private fnAddMohalla() {

    const modalRef = this.productFindService.open(AddmohallaComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetMohalla();

      if (receivedEntry > 0) {
        this.memberform.patchValue({ mohallaid: receivedEntry })
      }
    });

  }

  private fnAddStreet(type) {

    const modalRef = this.productFindService.open(AddstreetComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetStreet();

      if (receivedEntry > 0) {
        if (type === "STREET") {
          this.memberform.patchValue({ streetid: receivedEntry })
        }
        else if (type === "PRESENTSTREET") {
          this.memberform.patchValue({ presentstreetid: receivedEntry })
        }

      }
    });

  }

  private fnAddCity(type) {

    const modalRef = this.productFindService.open(AddcityComponent, { size: <any>'m' });

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

      this.fngetCity();

      if (receivedEntry > 0) {
        if (type === "CITY") {
          this.memberform.patchValue({ cityid: receivedEntry })
        }
        else if (type === "PRESENTCITY") {
          this.memberform.patchValue({ presentcityid: receivedEntry })
        }
        else if (type === "BIRTHCITY") {
          this.memberform.patchValue({ placeofbirthcityid: receivedEntry })
        }
        else if (type === "PROFESSIONALPLACECITY") {
          this.memberform.patchValue({ professionplacecityid: receivedEntry })
        }
      }
    });

  }


  fnChangeQualification() {
    for (let abo of this.qualificationColl) {
      if (abo.qualificationid === this.memberform.get("qualificationid").value) {
        this.qualificationyrs = abo.qualificationyrs
      }
    }
  }

  fnChangeRelationShip() {
    for (let abo of this.relationshipColl) {
      if (abo.relationshipid === this.memberform.get("relationshipid").value) {
        this.memberform.patchValue({ 'gender': abo.gender })
        this.memberform.patchValue({ 'maritalstatus': abo.maritalstatus })



        this.isdisablegender = false;
        this.isdisablemaritalstatus = false;

        if (abo.gender !== '' && abo.gender !== null) {
          this.isdisablegender = true;
        }
        if (abo.maritalstatus !== '' && abo.maritalstatus !== null) {
          this.isdisablemaritalstatus = true;
        }
      }
    }
  }
  fncheckValidatorMember() {
    var _relationshipname = "";

    var _relationship = this.memberform.get('relationshipid').value
    var _gender = this.memberform.get('gender').value
    var _maritalstatus = this.memberform.get('maritalstatus').value


    for (let abo of this.relationshipColl) {
      if (abo.relationshipid === _relationship) {
        _relationshipname = abo.relationshipname
      }
    }


    if (_relationshipname === 'DAUGHTER' && _gender === 'F' && _maritalstatus === 'M') {

      // this.memberform.controls['dateofbirth'].clearValidators();
      // this.memberform.controls['dateofbirth'].setErrors(null);
      // this.memberform.controls['dateofbirth'].setValidators(null);

      // this.memberform.controls['doorno'].clearValidators();
      // this.memberform.controls['doorno'].setErrors(null);
      // this.memberform.controls['doorno'].setValidators(null);

      // this.memberform.controls['streetid'].clearValidators();
      // this.memberform.controls['streetid'].setErrors(null);
      // this.memberform.controls['streetid'].setValidators(null);

      // this.memberform.controls['cityid'].clearValidators();
      // this.memberform.controls['cityid'].setErrors(null);
      // this.memberform.controls['cityid'].setValidators(null);

      // this.memberform.controls['mohallaid'].clearValidators();
      // this.memberform.controls['mohallaid'].setErrors(null);
      // this.memberform.controls['mohallaid'].setValidators(null);

      // this.memberform.controls['adhaarno'].clearValidators();
      // this.memberform.controls['adhaarno'].setErrors(null);
      // this.memberform.controls['adhaarno'].setValidators(null);

      // this.memberform.patchValue({ dateofbirth: null })
      // this.memberform.patchValue({ doorno: null })
      // this.memberform.patchValue({ streetid: null })
      // this.memberform.patchValue({ cityid: null })
      // this.memberform.patchValue({ mohallaid: null })
      // this.memberform.patchValue({ adhaarno: null })

    }
    else {
      this.memberform.controls['dateofbirth'].setValidators(Validators.required);
    }
  }




  private fnEditMode(deptBO) {
    this.isdisablerelationship = false;

    deptBO.dateofbirth = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.dateofbirth));
    this.memberform.setValue({

      memberid: deptBO.memberid,
      memberno: deptBO.memberno,
      membername: deptBO.membername,
      membernametamil: deptBO.membernametamil,
      fathername: deptBO.fathername,
      doorno: deptBO.doorno,
      streetid: deptBO.streetid,
      cityid: deptBO.cityid,
      isownhouse: deptBO.isownhouse,
      doornopresent: deptBO.doornopresent,
      presentstreetid: deptBO.presentstreetid,
      presentcityid: deptBO.presentcityid,
      isownhousepresent: deptBO.isownhousepresent,
      gender: deptBO.gender,
      maritalstatus: deptBO.maritalstatus,
      dateofbirth: deptBO.dateofbirth,
      placeofbirth: deptBO.placeofbirth,
      isstudent: deptBO.isstudent,
      qualificationid: deptBO.qualificationid,
      year: deptBO.year,
      instituteid: deptBO.instituteid,
      qualificationid1: deptBO.qualificationid1,
      year1: deptBO.year1,
      instituteid1: deptBO.instituteid1,
      qualificationid2: deptBO.qualificationid2,
      year2: deptBO.year2,
      instituteid2: deptBO.instituteid2,
      qualificationid3: deptBO.qualificationid3,
      year3: deptBO.year3,
      instituteid3: deptBO.instituteid3,
      professionid: deptBO.professionid,
      professionplace: deptBO.professionplace,
      annualincome: deptBO.annualincome,
      mohallaid: deptBO.mohallaid,
      mobile: deptBO.mobile,
      email: deptBO.email,
      adhaarno: deptBO.adhaarno,
      isvoter: deptBO.isvoter,
      isphy: deptBO.isphy,
      photopicurl: deptBO.photopicurl,
      memberimage: null,
      relationshipid: deptBO.relationshipid,
      isdifferentaddress: deptBO.isdifferentaddress,
      isusepermanataddress: deptBO.isusepermanataddress,
      placeofbirthcityid: deptBO.placeofbirthcityid,
      professionplacecityid: deptBO.professionplacecityid,
      cardno: deptBO.cardno,
      istemp: deptBO.istemp,
      fathermohallaid: deptBO.fathermohallaid,
      isdeathfather: deptBO.isdeathfather,
      isdeath: deptBO.isdeath,
      isskiptax: deptBO.isskiptax,
      currdate: new Date(),
      bloodgroup:  deptBO.bloodgroup,
      remarks:  deptBO.remarks,
      inactive: deptBO.inactive,

    });

    this.qualificationyrs = deptBO.qualificationyrs

    this.picurl = deptBO.photopicurl

    if (deptBO.isfamilyhead) {
      this.isdisablerelationship = true;
      this.relationshipname = deptBO.relationshipname
    }
    this.fncheckValidatorMember()


    this.isdisablegender = true    
    this.isdisablerelationship = true

    if (deptBO.relationshipname === 'FAMILYHEAD' || deptBO.relationshipname === 'WIFE') {
      this.isdisablemaritalstatus = true
    }

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }
  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }
  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

  fnMomentDateFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY');//hh:mm a  
    }
    else {
      return '';
    }
  }

  fnMomentDateTimeFormatter(params) {
    if (params.value) {
      return moment(params.value).format('DD-MM-YYYY hh:mm a');//  
    }
    else {
      return '';
    }
  }
  public decline() {
    this.activeModal.close(false);
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
